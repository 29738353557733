<template>
  <div class="center">
    <SearchBar />
    <div style="line-height: 22px; font-size: 14px; display: flex; align-items: center">
      <Breadcrumb separator="<div class='iconfonts'></div>">
        <BreadcrumbItem to="/center/info" class="can-click black_1">个人中心</BreadcrumbItem>
        <BreadcrumbItem
          v-if="curentMap[currentName] || curScendReleativeMap[routerName]"
          :to="curScendReleativeMap[routerName] ? { name: curScendReleativeMap[routerName] } : ''"
          :class="curScendReleativeMap[currentName] ? 'last' : 'can-click'"
        >
          {{ curentMap[currentName] || curentMap[curScendReleativeMap[routerName]] }}
        </BreadcrumbItem>
        <BreadcrumbItem v-if="scendMap[routerName]" class="last">{{ scendMap[routerName] }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="menu">
          <div class="menu-item bold" :class="{ current: currentName === 'CenterInfo' }" @click="toPage('/center/info','个人中心首页')">个人中心首页</div>
          <div class="menu-item bold gray" style="cursor: text">账号管理</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CenterEnterprise' }" @click="toPage('/center/enterprise','我的企业')">我的企业</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CenterCertification' }" @click="toPage('/center/certification','资质管理')">资质管理</div>
          <div style="position: relative;">
            <div class="menu-item gray" :class="{ current: currentName === 'CenterAddr' }" @click="toPage('/center/addr','收货地址')">收货地址</div>
            <!-- <img v-if="showHint === '1'" style="position: absolute;top: 0px;left: 83px;" src="../../../assets/center/addr_hint.svg">
            <Icon v-if="showHint === '1'" style="position: absolute;top: 10px;right: -17px;color: white;background: black;cursor: pointer;" type="ios-close-circle-outline" @click="cancelHint"/> -->
          </div>
          <div class="menu-item gray" :class="{ current: currentName === 'CenterMerchant' }" @click="toPage('/center/merchant','我的商家')">我的商家</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CenterCollection' }" @click="toPage('/center/collection','我的收藏')">我的收藏</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CenterFootmark' }" @click="toPage('/center/footmark','我的足迹')">我的足迹</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CenterPurchaseList' }" @click="toPage('/center/purchaseList','常购清单')">常购清单</div>
          <div class="menu-item gray" :class="{ current: currentName === 'modify_mobile' }" @click="toPage('/center/modifyMobile','修改手机号')">修改手机号</div>
          <div class="menu-item gray" :class="{ current: currentName === 'modifyPwd' }" @click="toPage('/center/modifyPwd','修改密码')">修改密码</div>
          <div class="menu-item bold gray" style="cursor: text">我的订单</div>
          <div class="menu-item gray" :class="{ current: currentName === 'SpeedOrder' }" @click="toPage('/speedOrder/index','飞速下单')">飞速下单</div>
          <div class="menu-item gray" :class="{ current: currentName === 'PurchasePlan' }" @click="toPage('/purchasePlan/index','采购计划')">采购计划</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CodeMapping' }" @click="toPage('/codeMapping/index','对码关系管理')">对码关系管理</div>
          <div class="menu-item gray" :class="{ current: currentName === 'OrderList' }" @click="toPage('/order/list','商城订单')">商城订单</div>
          <div class="menu-item gray" :class="{ current: currentName === 'RefundList' }" @click="toPage('/order/refund/index','退款/售后')">退款/售后</div>
          <div class="menu-item gray" :class="{ current: currentName === 'OutOfStock' }" @click="toPage('/order/outOfStock/index','缺货登记')">缺货登记</div>
          <!-- <div class="menu-item gray" :class="{ current: currentName === 'BatchOrderList' }" @click="toPage('/batch/selectShop')">批量采购</div> -->
          <div class="menu-item gray" :class="{ current: currentName === 'AllOrderList' }" @click="toPage('/allOrder/list','全渠道订单')">全渠道订单</div>
          <div class="menu-item bold gray" style="cursor: text">财务管理</div>
          <div class="menu-item gray" :class="{ current: currentName === 'CouponList' }" @click="toPage('/coupon/index','我的优惠券')">我的优惠券</div>
          <div class="menu-item gray" :class="{ current: ['Lottery', 'Squared'].includes(currentName) }" @click="toPage('/lottery/index','抽奖中心')">抽奖中心</div>
          <div class="menu-item gray" :class="{ current: ['RedEnvelope'].includes(currentName) }" @click="toPage('/redEnvelope/index','我的红包')">我的红包</div>
          <div class="menu-item gray" :class="{ current: currentName === 'Invoice' }" @click="toPage('/center/invoice','我的发票')">我的发票</div>
          <div class="menu-item gray" :class="{ current: currentName === 'MsgList' }" @click="toPage('/msg/index','消息通知')">消息通知</div>
          <!-- <div class="menu-item bold gray" style="cursor: text">资料下载</div>
          <div class="menu-item gray" :class="{ current: currentName === 'DocumentDownload' }" @click="toPage('/download/document','首营资料')">首营资料</div>
          <div class="menu-item gray" :class="{ current: currentName === 'DrugReportDonload' }" @click="toPage('/download/report','药检报告')">药检报告</div>
          <div class="menu-item gray" :class="{ current: currentName === 'InvoiceDownload' }" @click="toPage('/download/invoice','电子发票')">电子发票</div> -->
          <div class="menu-item bold gray" style="cursor: pointer" @click="toPage('/helperCenter','帮助中心')">帮助中心</div>
          <div class="menu-item gray" :class="{ current: currentName === 'Feedback' }" @click="toPage('/feedback/index','意见反馈')">意见反馈</div>
        </div>
      </div>
      <div class="content-right">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue'
import { Icon } from 'view-design';
export default {
  name: 'CenterIndex',
  components: {
    SearchBar
  },
  data() {
    return {
      currentName: 'CenterInfo',
      curentMap: {
        CenterInfo: '个人中心首页',
        CenterEnterprise: '我的企业',
        CenterCertification: '资质管理',
        CenterAddr: '收货地址',
        CenterMerchant: '我的商家',
        CenterCollection: '我的收藏',
        CenterFootmark: '我的足迹',
        CenterPurchaseList: '常购清单',
        OrderList: '商城订单',
        RefundList: '退款/售后',
        AllOrderList: '全渠道订单',
        OutOfStock: '缺货登记',
        CouponList: '我的优惠券',
        MsgList: '消息通知',
        modify_mobile: '修改手机号',
        modifyPwd: '修改密码',
        Lottery: '抽奖中心',
        Squared: '九宫格抽奖',
        RedEnvelope: '我的红包',
        DrugReportDonload: '药检报告',
        DocumentDownload: '首营资料',
        Invoice: '我的发票',
        Feedback: '意见反馈'
      },
      scendMap: {
        CompanyInfo: '基础信息变更',
        ReceiverAddress: '收货地址变更',
        CertificationUpdate: '资质信息变更',
        Consignor: '委托人信息变更',
        CustTypeChange: '信息变更',
        AddMustCustCert: '补充资质',
        UnbindEnterprise: '解绑企业'
      },
      curScendReleativeMap: {
        CustTypeChange: 'CenterEnterprise',
        AddMustCustCert: 'CenterEnterprise',
        CompanyInfo: 'CenterEnterprise',
        ReceiverAddress: 'CenterEnterprise',
        UnbindEnterprise: 'CenterEnterprise',
        CertificationUpdate: 'CenterCertification',
        Consignor: 'CenterCertification',
        DrugReportDonload: 'DrugReportDonload',
        DocumentDownload: 'DocumentDownload',
        InvoiceDownload: 'InvoiceDownload'
      },
      showHint: '1',
    }
  },
  mounted(){
    this.showHint =  localStorage.getItem("showHint") === '0' ? '0' : '1' 
  },
  computed: {
    routerName() {
      return this.$route.query.name || this.$route.name
    }
  },
  watch: {
    $route: {
      handler(route) {
        document.documentElement.scrollTop = 0
        console.log(route.name)
        this.currentName = route.name
      },
      immediate: true
    }
  },
  methods: {
    cancelHint(){
      this.showHint = '0'
      localStorage.setItem("showHint",'0')
    },
    goBack() {
      this.$router.go(-1)
    },
    toPage(url,name) {
      this.$zhuge.track('个人中心-切换tab', {
        'tab名称' : name,
      })
      this.$router.push({
        path: url
      })
    }
  }
}
</script>
<style lang="less">
.center {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
  cursor: default;
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    gap: 12px;
    &-left {
      width: 200px;
      height: 100%;
      padding: 18px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 8px;
      .menu {
        &-item {
          padding: 6px 15px;
          color: #000;
          font-size: 16px;
          &.bold {
            margin-top: 14px;
            font-weight: 700;
          }
          &.gray {
            color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
          }
          &.current {
            color: #ce7720;
            font-weight: 500;
          }
        }
      }
    }
    &-right {
      flex: 1;
      width: 980px;
      // height: 700px;
      .box {
        margin-bottom: 12px;
        padding: 16px 24px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--font_white-0, #FFF);
        background-color: #fff;
      
        &-content {
          display: flex;
          gap: 20px;
          padding-top: 0px;
          &-step {
            width: 170px;
            border-right: 1px dashed #dcdcdc;
          }
        }
      }
      .caption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        color: var(--font_black-1, rgba(0, 0, 0, 0.90));
        /* medium/14 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        &-right {
          color: var(--font_black-2, rgba(0, 0, 0, 0.60));
          /* regular/14 */
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }

        &-right:hover{
          color: #e67d00 !important;
          /* regular/14 */
          font-family: "PingFang SC"!important;
          font-size: 14px!important;
          font-style: normal!important;
          font-weight: 400!important;
          line-height: 22px!important;
        }
      }
      .grid {
        display: flex;
        align-items: center;
        gap: 15px;
        line-height: 22px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        &-hover {
          .grid-item {
            transition: background-color 0.5s ease-in;
            &:hover {
              background-color: #f5f5f5;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }

        &-item {
          position: relative;
          flex: 1;
          padding: 10px;
          background-color: #fff;
          .count {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 26px;
            font-size: 20px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
          }
          &.merchat {
            text-align: left;
            .count {
              justify-content: flex-start;
              line-height: 40px;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
  .margin-bottom-20 {
    margin-bottom: 10px;
  }
}
.last {
  color: rgba(0, 0, 0, 0.9);
}

.iconfonts {
  display: inline-block;
}
.iconfonts::before {
  content: url('../../../assets/images/common/gery-right-arrow.png');
  display: inline-block;
  position: relative;
  top: 1px;
}

.can-click {
  cursor: pointer;
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  text-align: center;

  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.black_1 {
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  text-align: center;
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
</style>
