<!-- 资质到期弹窗，首页部分，非必要资质 -->
<template>
  <div>
    <Modal v-model="isShow" width="400px" :closable="false">
        <template #header>
            <div></div>
        </template>
        <div class="title">
          <img src="@/assets/images/common/info_icon.png" alt="" class="img">
          <span>提示</span>
          <div class="close-icon">
            <img src="@/assets/images/common/cancel.png" alt="" class="img">
          </div>
        </div>
        <div class="content">
          {{ content }}
        </div>
        <div class="bottom">
          <Button @click="isShow = false">取消</Button>
          <Button type="primary" @click="confirm">去更新</Button>
        </div>
        <template #footer>
            <div></div>
        </template>
    </Modal>
  </div>
</template>

<script>
import { pullAll } from '@/common/service/home'
import { Button } from 'view-design';
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    customerList: Object // 企业列表
  },
  data() {
    return {
      isShow : false,
      content : ""
    }
  },
  methods: {
    confirm() {
      this.isShow = false
      this.$router.push({
        path: '/center/certification'
      })
    },
    // 调接口，判断是否有过期资质
    async check() {
      const { data } = await pullAll()
      if (data.expireTag === 1) {
        this.content = `你的“${data.expireCertNameList.join('”、“')}”已过期，请尽快更新证照`
        this.isShow = true
      }
    
    }
  }
}
</script>

<style scoped lang='less'>

::v-deep .ivu-modal-content {
  display: flex;
  border-radius: 8px;
  background: var(--font_white-0, #FFF);
}

::v-deep .ivu-modal-header {
 height: 0px;
 padding: 0px;
 margin: 0px;
}

::v-deep .ivu-modal-footer {
 height: 0px;
 padding: 0px;
 margin: 0px;
}

::v-deep .ivu-modal-body{
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  gap: 16px;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      color: var(--fontcolor-black-1, rgba(0, 0, 0, 0.90));
      /* medium/16 */
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .close-icon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .img{
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  .content {
    color: var(--font_black-1, rgba(0, 0, 0, 0.90));
    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-left: 23px;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 12px;
  }
}

</style>
