<template>
  <div>
    <div id="navtop" class="top-menu-wrapper" :style="[topPosition]">
      <div class="top-menu-wrapper_content">
        <div class="left">
          <!-- <span class="location"><Icon class="location-icon" type="ios-pin-outline" />{{ province }}</span> -->
          <span v-if="topConfig.welcomeText" class="welcome">
            {{ topConfig.welcomeText }}
          </span>
          <span v-if="!topConfig.welcomeText" class="welcome">欢迎来到润药商城！</span>
          <span v-show="relateCorp" class="top-shop-name" @click="changeCustom">
            {{ selectCustom.customName || '默认企业' }}
          </span>
          <Icon :type="!showCustomeList ? 'ios-arrow-down' : 'ios-arrow-up'" style="margin-right: 16px;" />
          <span v-if="hasLogin" class="quit" @click="quit">退出</span>
        </div>
        <div class="right">
          <div class="item" @click="goHome">首页</div>
          <div class="item" @click="openPage('/center/index','个人中心')">个人中心</div>
          <div class="item" @click="myOrderHandler">我的订单</div>
          <div class="item" @click="openPage('/licence','平台资质')">平台资质</div>
          <!-- <div v-if="hasLogin" class="item" @click="openPage('/helperCenter')">帮助中心</div>
          <div v-if="hasLogin" class="item" @click="openPage('/feedback/index')">意见反馈</div> -->
          <Poptip placement="bottom" offset="7 " padding="4px 4px" trigger="hover" width="116px">
            <div class="item">
              客服服务
              <Icon type="ios-arrow-down" />
            </div>
            <template #content>
              <div class="service-box">
                <div class="service-item" @click.stop="handleService('helperCenter','帮助中心')">
                  帮助中心
                </div>
                <div class="service-item" @click.stop="handleService('feedback','意见反馈')">
                  意见反馈
                </div>
                <div class="service-item" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
                  <span>联系客服</span>
                  <Icon type="ios-arrow-forward" />
                </div>
                <div v-if="isHover || isHoverLink" class="service-contact" @mouseover="onMouseOver" @mouseleave="onMouseLeave" @click.stop="onCopy()">
                  {{ topConfig.linkMethod }}
                  <span class="copy-text">复制</span>
                </div>
              </div>
            </template>
          </Poptip>
          <div v-if="hasLogin" class="item" @click="openPage('/ProductEntry','商家入驻')">商家入驻</div>
          <!-- <div v-if="topConfig.linkMethod" class="item">客服热线：{{ topConfig.linkMethod }}</div> -->
          <!-- <Dropdown @on-click="handleDownload">
            <div class="item">
              资料下载
              <Icon type="ios-arrow-down" />
            </div>
            <template #list>
              <DropdownMenu>
                <DropdownItem name="doc">首营资料</DropdownItem>
                <DropdownItem name="report">药检报告</DropdownItem>
                <DropdownItem name="invoice">电子发票</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown> -->
          <!-- <Poptip popper-class="download-poptip" placement="bottom" offset="7 " padding="4px 4px" trigger="hover" width="100px">
            <div class="item">
              资料下载
              <Icon type="ios-arrow-down" />
            </div>
            <template #content>
              <div class="download-box">
                <div class="download-item" @click.stop="handleDownload('doc','首营资料')">
                  首营资料
                </div>
                <div class="download-item" @click.stop="handleDownload('report','药检报告')">
                  药检报告
                </div>
                <div class="download-item" @click.stop="handleDownload('invoice','电子发票')">
                  电子发票
                </div>
              </div>
            </template>
          </Poptip> -->
          <Poptip popper-class="phone-poptip" placement="bottom-end" offset="7 " padding="4px 4px" trigger="hover" width="268px">
            <div class="phone-item">
              <svg-icon name="smartphone" className="smartphone"></svg-icon>
              <svg-icon name="smartphone-hover" className="smartphone-hover"></svg-icon>
              手机版
            </div>
            <template #content>
              <div class="code-container">
                <div class="code-content">
                  <div><img :src="require('@/assets/login/APP_code.png')" alt=""></div>
                  <div class="code-title">润药商城APP</div>
                </div>
                <div class="code-content">
                  <div><img style="border-radius: 38px;" :src="require('@/assets/login/qr_code.png')" alt=""></div>
                  <div class="code-title">润药商城小程序</div>
                </div>
              </div>
            </template>
          </Poptip>
        </div>
      </div>
    </div>
    <div v-show="showCustomList" class="shop-name">
      <div class="list">
        <h3 class="title"><span>选择企业</span>
          <Icon class="ico" type="md-close" @click="showCustomList = false" />
        </h3>
        <div class="item-content">
          <RadioGroup v-model="selectedCancleOrder" placement="column" @on-change="confirm">
            <Radio v-for="(item, index) in dataList" :key="index" class="list-item" active-color="#ff9900"
              :label="item.customerId">
              <div class="content-box">
                <div class="custome-name">
                  {{ item.customName }}
                </div>
                <div class="custome-addr">
                  <span>{{ item.districtName }}</span>
                  <span>{{ item.receiveAddr }}</span>
                </div>
              </div>
              <div v-if="item.addrCount > 1" class="change-address"
                @click.stop.prevent="chooseAddress(item.customerId, item.customName, item, $event)">切换地址</div>
            </Radio>
          </RadioGroup>
        </div>
        <div v-show="showAddress" class="address-wrapper" :style="{ top: `calc(${top}px - 60px)` }">
          <div class="address-content">
            <div class="address-input">
              <Input v-model="inputValue" search placeholder="搜索" clearable enter-button
                @on-search="handelSearchAddress" @on-change="handelChange" />
            </div>
            <div class="address-fixation">
              <RadioGroup v-model="addressId" class="doc-glop" placement="column" @on-change="confirmAddress">
                <Radio v-for="(item, index) in dataListAddress" :key="index" class="address-content-item"
                  active-color="#ff9900" :label="item.receiveAddrId">
                  <div class="content">
                    <div class="address">
                      {{ item.receiveDistrict.provinceName + item.receiveDistrict.cityName +
                        item.receiveDistrict.districtName + item.receiveAddr }}
                    </div>
                    <div class="phone">
                      <div>{{ item.receiveContact + ' ' + item.receiveTel }}</div>
                    </div>
                  </div>
                </Radio>
              </RadioGroup>
              <template v-if="status === 1">
                <div class="address-box">
                  <div class="address-box-loading">
                    <Spin fix>
                      <Icon type="ios-loading" size="30" class="demo-spin-icon-load" />
                    </Spin>
                  </div>
                </div>
              </template>
              <template v-else-if="status === 3">
                <div class="address-box">
                  <div class="address-box-loading">尚未找到对应的地址！</div>
                </div>
              </template>
              <template v-else-if="status === 4">
                <div class="address-box">
                  <div class="address-box-loading">没有更多了</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CheckQualifications ref="checkQualification" />
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { getLogout, obtainCustomerListAPI, switchReceiveAdd, switchUserIdentityAPI } from '@/common/service'
import { getConfigByCode, getPageAddrListByCustomerId } from '@/common/service/cms'
import router from '@/router'
import { clearLoginInfo, getStorageSync, setStorageSync } from '@/common/utils'
import CheckQualifications from '@/views/home/coms/checkQualifications'
import { mapMutations, mapGetters } from 'vuex'
import { Spin } from 'view-design'
import { copy } from '@/common/uni.js'

const pageParams = (customId = '', pageNum = 1) => ({
  pageSize: 10,
  pageNum,
  ascs: [],
  descs: [],
  condition: {
    customId,
    addressDetail: ''
  }
})
export default {
  name: 'TopMenu',
  components: {
    Spin,
    CheckQualifications
  },
  props: {
    msg: String
  },
  data() {
    return {
      showCustomeList: false,
      selectedCancleOrder: null,
      customerId: null,
      dataList: [],
      customerName: '',
      chooseId: null, // 已选择地址id
      addressId: null,
      dataListAddress: [],
      selectCustom: {},
      showMobile: false,
      relateCorp: false,
      showAddress: false,
      showCustomList: false,
      topConfig: {
        firstImg: '',
        linkMethod: '',
        secondImg: '',
        welcomeText: ''
      },
      status: 1,
      top: 0,
      selectedItem: {},
      inputValue: '',
      pageParams: null,
      loading: true,
      userIsUnbind: false,
      isHover: false, // 是否hover
      isHoverLink: false, // 是否hover
    }
  },
  inject: ['topApp'],
  computed: {
    hasLogin() {
      return getStorageSync('accessToken') || null
    },
    province() {
      if (!this.selectCustom?.districtName) {
        return null
      }
      const splitKey = this.selectCustom?.districtName.includes('省') ? '省' : '区'
      let result = this.selectCustom?.districtName?.split(splitKey)[0] || '默认'
      if (splitKey === '区') {
        result = result + '区'
      }
      return result
    },
    topPosition() {
      let position = {
        position: 'sticky'
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.name == 'Home') {
        position = {
          position: 'relative'
        }
      }
      return position
    }
  },
  async created() {
    if (this.hasLogin) {
      await this.obtainCustomerList()
    }
    await this.getTopMenuConfig()
    this.$bus.$on('resetCurrentCustomer', async (bool) => {
      this.userIsUnbind = bool
      await this.obtainCustomerList()
    })
  },
  mounted() {
    // const $list = document.querySelector('.shop-name ')
    // $list.addEventListener('click', this.bodyClick)
  },
  beforeDestroy() {
    // const $list = document.querySelector('.shop-name ')
    // $list?.removeEventListener('click', this.bodyClick)
  },
  methods: {
    uploadZhuge(name){
      this.$zhuge.track('切换顶部Tab', {
        'tab名称' : name,
      })
    },
    handeleRunScroll() {
      const oNode = document.querySelector('.address-fixation')
      // 视口高度
      const viewOffsetHeight = oNode.offsetHeight
      // 文档内容高度
      oNode.addEventListener('scroll', e => {
        const contentOffsetHeight = document.querySelector('.doc-glop').offsetHeight
        const scrollTop = e.target.scrollTop || 0
        if (scrollTop >= contentOffsetHeight - viewOffsetHeight) {
          if (this.loading) {
            this.loading = false
            this.pageParams.pageNum += 1
            this.getDataListAddress(data => {
              // const num = Math.ceil(data.total / this.pageParams.pageSize)
              const list = data.records || []
              this.loading = true
              list.map(item => {
                this.dataListAddress.push(item)
              })
              if (list.length === 0) {
                this.status = 4
                this.loading = false
              }
            })
          }
        }
      })
    },
    ...mapMutations({
      setKeyword: 'product/SET_KEYWORD'
    }),
    showAddressFn(e) {
      this.showAddress = false
    },
    handleDownload(val,name) {
      if (val === 'report') {
        this.openPage('/download/report',name)
      } else if (val === 'doc') {
        this.openPage('/download/document',name)
      } else if (val === 'invoice') {
        this.openPage('/download/invoice',name)
      }
    },
    // 客服服务
    handleService(val,name) {
      if (val === 'helperCenter') {
        this.openPage('/helperCenter',name)
      } else if (val === 'feedback') {
        this.openPage('/feedback/index',name)
      } else if (val === 'service') {

      }
    },
    showCustomListFn(e) {
      // this.showCustomList = !this.showCustomList
    },
    changeCustom() {
      this.showCustomList = !this.showCustomList
    },
    bodyClick(e) {
      const left = document.querySelector('.shop-name .list')
      if (left && !left.contains(e.target)) {
        this.showCustomList = false
      }
    },
    getFilePath,
    // 获取企业列表
    async obtainCustomerList() {
      const res = await obtainCustomerListAPI()
      const data = res.data
      this.dataList = data
      if (!this.dataList?.length && this?.topApp?.showAttachCompanyInfo) {
        this.$store.commit('setDisassociationState', false)
        this.toDisassociation()
      } else if (res?.code === '603') {
        this.$store.commit('setDisassociationState', true)
        this.$message.error('资质审核中，请耐心等待')
      } else {
        this.$store.commit('setDisassociationState', true)
        this.relateCorp = true
      }

      if (this.userIsUnbind) {
        // 用户解绑企业后，找不到选中公司信息，则默认选择已认证的或第一个
        const selCustomer = this.dataList.find(d => d.custState === 'auth') || this.dataList[0]
        this.selectCustom = selCustomer
        this.selectedCancleOrder = this.customerId = selCustomer?.customerId
        this.confirm(this.selectedCancleOrder)
        this.userIsUnbind = false
      } else {
        this.dataList?.forEach(item => {
          if (item.inUse) {
            this.selectCustom = item
            // 切换用户时，保存新用户信息
            // this.$store.dispatch('common/setCustomerInfo', item || {})
            this.selectedCancleOrder = this.customerId = item.customerId
          }
        })
      }
    },
    // 去关联
    toDisassociation() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>请先关联采购主体（药店/医院/诊所/企业）。未关联前无法获取商品全部内容以及采购下单</p>',
        okText: '去关联',
        cancelText: '暂不',
        onOk: () => {
          router.push('/affiliated')
        },
        onCancel: () => {
          // this.$message.info('Clicked cancel')
        }
      })
    },
    myOrderHandler() {
      if (!this.dataList.length && this?.topApp?.showAttachCompanyInfo) {
        this.toDisassociation()
      } else {
        this.openPage('/order','我的订单')
      }
    },
    // 确定企业
    async confirm(item) {
      let time = 0
      if (item.controlState === 'frozen') {
        time = 2000
        this.$message.error('您的账号已冻结，请联系平台运营人员处理。')
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.name == 'wxPay' || this.$route.name == 'orderPay') {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>订单还未支付，请确定是否取消付款</p>',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            setTimeout(async () => {
              const data = await switchUserIdentityAPI(this.selectedCancleOrder)
              if (data.code === 200) {
                this.showCustomList = false
                this.$refs.checkQualification.check()
                this.selectCustom = this.dataList?.find(itemX => itemX.customerId === item) || {}
                this.$store.commit('setLoginOutRefresh')
                this.$router.push('/')
              } else {
                this.$message.error(data.data)
              }
            }, time)
          },
          onCancel: () => {
            this.selectedCancleOrder = this.customerId
          }
        })
        return
      }
      setTimeout(async () => {
        const data = await switchUserIdentityAPI(this.selectedCancleOrder)
        if (data.code === 200) {
          this.showCustomList = false
          this.selectCustom = this.dataList?.find(itemX => itemX.customerId === item) || {}
          this.$store.commit('setLoginOutRefresh')
          this.$router.go(0)
          setStorageSync('isLoginCome', 1)
        } else {
          this.$message.error(data.data)
        }
      }, time)
    },
    chooseAddress(customId, customerName, item, event) {
      this.top = event.clientY
      this.showAddress = true
      this.pageParams = pageParams(customId)
      this.dataListAddress = []
      this.inputValue = ''
      // 初次调用
      this.getDataListAddress(data => {
        const list = data.records || []
        this.dataListAddress = list
        setTimeout(() => {
          this.handeleRunScroll()
        }, 100)
      })
    },
    getDataListAddress(callBack) {
      this.status = 1
      getPageAddrListByCustomerId(this.pageParams).then(res => {
        const data = res.data || {}
        const list = data.records || []
        // 数据预处理
        list.forEach(item => {
          item.fullAddress = item.receiveDistrict.provinceName + item.receiveDistrict.cityName + item.receiveDistrict.districtName + item.receiveAddr + item.receiveContact + item.receiveTel
        })
        // 选中当前
        list.forEach(item => {
          if (item.isCheck === 1) {
            this.addressId = this.chooseId = item.receiveAddrId
          }
        })

        const pageNum = this.pageParams.pageNum
        if (pageNum === 1 && list.length === 0) {
          this.status = 3
        } else {
          this.status = 2
        }
        if (callBack) {
          callBack(data)
        }
      })
      // {
      //   console.log(await getPageAddrListByCustomerId(temp))
      // }
      // const { data } = await getAddrListByCustomerId(customerId)
      // const list = data || []
      // this.dataListAddress
      // data.forEach((item) => {
      //   item.fullAddress =
      //     item.receiveDistrict.provinceName +
      //     item.receiveDistrict.cityName +
      //     item.receiveDistrict.districtName +
      //     item.receiveAddr +
      //     item.receiveContact +
      //     item.receiveTel
      // })
      // this.dataListAddress = data
      // this.originData = data
      // this.dataListAddress.forEach((item) => {
      //   if (item.isCheck === 1) {
      //     this.addressId = this.chooseId = item.receiveAddrId
      //   }
      // })
      // successCb && successCb()
      // if (this.dataListAddress.length === 0) {
      //   this.$message.error('尚未找到对应的地址！')
      //   this.showAddress = false
      // }
    },
    // 切换显示逻辑
    async confirmAddress(val) {
      try {
        if (this.chooseId === this.addressId) return // chooseId没变化不请求接口
        const data = await switchReceiveAdd(this.addressId)
        if (data.code === 200) {
          /*       this.$refs.toast?.showToast({
            toastTitle: '切换地址成功',
            toastIcon: '/static/images/cart/success_icon.png',
            showStatus: true,
          })*/
          location.reload()
          this.$message.info('切换地址成功')
          document.querySelector('.top-menu-wrapper_content .list').style.display = 'none'
        } else {
          this.$message.error('切换地址失败！')
          /*        this.$refs.toast?.showToast({
            toastTitle: data.data,
            toastIcon: '/static/images/cart/tip_icon.png',
            showStatus: true,
          })*/
        }
      } catch (error) {
        this.$Message.warning(error?.data?.message)
      }
    },
    async getTopMenuConfig() {
      const { data } = await getConfigByCode('top_opt')
      this.topConfig = data?.configContent || {}
    },
    async quit() {
      try {
        this.loading = true
        const res = await getLogout()
        this.loading = false
        if (res.code === 200) {
          clearLoginInfo()
          this.$router.replace('/login')
          // 通知退出登陆
          this.$store.commit('setLoginOutRefresh')
          // 退出登录清空购物车数据
          this.$bus.$emit('clearCarData')
          // 退出成功清除飞速下单商家信息
          localStorage.setItem('selectOrgName', '')
          localStorage.setItem('selectOrgId', '')
        }
        // 推出清空搜索结果
        setStorageSync('history_record', [])
      } catch {
        //  某某某
      }
    },
    openPage(url,name) {
      window.open(url, '_blank')
    },
    // 返回首页
    goHome() {
      this.setKeyword('')
      if (this.$route.path !== '/') this.$router.push('/')
      this.uploadZhuge("首页")
    },
    handelSearchAddress(value) {
      const inputvalue = value || ''
      if (!inputvalue) {
        this.handelClear()
      } else {
        // 保存 customId
        const customId = this.pageParams.condition.customId
        this.pageParams = pageParams(customId)
        this.pageParams.condition.customId = customId
        this.pageParams.condition.addressDetail = inputvalue
        this.dataListAddress = []
        this.loading = true
        this.getDataListAddress(data => {
          const list = data.records || []
          this.dataListAddress = list
          setTimeout(() => {
            this.handeleRunScroll()
          }, 100)
        })
      }
    },
    // 删除结束,直接清空情况处理
    handelChange(e) {
      const val = e?.target?.value ?? ''
      if (!val) {
        // 保存 customId
        const customId = this.pageParams.condition.customId
        // 重置数据
        this.pageParams = pageParams(customId)
        this.pageParams.condition.customId = customId
        this.dataListAddress = []
        this.inputValue = ''
        this.loading = true
        this.getDataListAddress(data => {
          const list = data.records || []
          this.dataListAddress = list
          setTimeout(() => {
            this.handeleRunScroll()
          }, 100)
        })
      }
    },
    handleMouseOver() {
      this.isHover = true
    },
    handleMouseLeave() {
      setTimeout(() => {
        if (!this.isHoverLink) {
          this.isHover = false
        }
      }, 600)
    },
    onMouseOver() {
      this.isHoverLink = true
      this.isHover = false
    },
    onMouseLeave() {
      this.isHoverLink = false
      this.isHover = false
    },
    // 复制
    onCopy() {
      this.$zhuge.track('客服-点击客服按钮', {
        '店铺名称':undefined,
        '客服类型':'平台客服'
      })
      copy(this.topConfig.linkMethod)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
.top-menu-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #fafafa;
  z-index: 1000;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  .top-menu-wrapper_content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .left {
      display: flex;
      align-items: center;

      .location {
        color: rgba(0, 0, 0, 0.6);
        margin-right: 16px;

        &-icon {
          margin-right: 4px;
        }
      }

      >span {
        line-height: 36px;
        height: 36px;
      }

      .top-shop-name {
        display: inline-block;
        color: rgba(0, 0, 0, 0.6);

        position: relative;
        max-width: 540px;
        white-space: nowrap;
        /* 禁止文本换行 */
        overflow: hidden;
        /* 隐藏超出范围的内容 */
        text-overflow: ellipsis;
        /* 使用省略号 */
      }

      .welcome {
        color: #f99d33;
        margin-right: 16px;
      }

      .quit {
        color: rgba(0, 0, 0, 0.25);

        &:hover {
          cursor: pointer;
          color: #ce7720;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .item {
        // display: inline-block;
        padding: 0 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        a {
          color: rgba(0, 0, 0, 0.6);
        }

        &:hover {
          color: #ce7720;
        }

        img {
          margin-right: 2px;
        }
      }
    }
  }

  .smartphone-icon, .smartphone-icon-hover {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .smartphone-icon-hover {
    display: none;
  }
}
::v-deep .ivu-radio-wrapper {
  margin-right: 0;
}
.shop-name {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 999;

  &:hover {
    .list {
      display: block;
    }
  }

  .address-wrapper {
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 402px;
    top: 0;
  }

  .list {
    border-radius: 8px;
    padding-bottom: 8px;
    display: block;
    position: absolute;
    width: 404px;
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    z-index: 999;
    top: 40px;
    left: 243px;

    .address-content {
      position: absolute;
      max-height: 400px;
      overflow-y: auto;
      box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
      /*          left: calc(100%);*/
      top: 4px;
      z-index: 9999;
      background-color: #fff;

      .address-fixation {
        max-height: 350px;
        overflow-y: scroll;
      }

      .address-box {
        .address-box-loading {
          text-align: center;
          width: 200px;
          height: 50px;
          margin: 0 auto;
          position: relative;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .address-input {
        width: 364px;
        padding: 8px 24px 8px 24px;
      }

      .address-content-item {
        display: flex;
        padding: 8px 24px;
        margin-bottom: 5px;

        ::v-deep .ivu-radio {
          position: relative;
        }

        &:hover {
          background: #f5f5f5;
        }

        word-wrap: break-word;
        white-space: pre-wrap !important;

        .address {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
        }

        .phone {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .item-content {
      max-height: 400px;
      overflow-y: auto;

      ::v-deep .ivu-radio-group {
        width: 100%;
        word-wrap: break-word;
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ico {
        cursor: pointer;
      }
    }

    .list-item {
      padding: 8px 24px;
      display: flex;

      &:hover {
        background: #f5f5f5;
      }

      .content-box {
        flex: 1;
        word-wrap: break-word;
        max-width: calc(100% - 44px);
        white-space: pre-wrap !important;

        >div {
          max-width: 100%;
          word-break: break-word;
        }
      }

      .custome-name {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }

      .custome-addr {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
      }

      .change-address {
        position: relative;
        cursor: pointer;
        max-height: 50px;
        overflow-y: auto;
        color: #3853e2;

        .address-content {
          position: absolute;
          top: 4px;
          z-index: 9999;
          background-color: #fff;

          .address-content-item {
            display: flex;
          }
        }
      }
    }
  }
}

.demo-spin-col .circular {
  width: 25px;
  height: 25px;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}

.menu-copy {
  margin-left: 8px;
}

::v-deep .ivu-dropdown .ivu-select-dropdown {
  width: auto;
}

::v-deep .ivu-dropdown-item {
  display: flex;
  align-items: center;
}

::v-deep .ivu-dropdown-menu {
  padding: 0 4px;

  .ivu-dropdown-item {
    &:hover {
      color: rgba(230, 125, 0, 1);
      background: rgba(255, 248, 232, 1);
    }
  }
}

::v-deep .ivu-poptip-popper {
  min-width: 116px;
  top: 28px !important;
  &.download-poptip {
    min-width: 100px;
  }
}

.service-box {
  .service-item {
    width: 106px;
    height: 32px;
    padding: 0px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
      background: rgba(255, 248, 232, 1);
      color: rgba(230, 125, 0, 1);
    }
  }
  .service-contact {
    position: absolute;
    bottom: 7px;
    left: 117px;
    height: 40px;
    padding: 0 12px;
    border-radius: 0px 4px 4px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    .copy-text {
      font-size: 12px;
      color: rgba(36, 56, 188, 1);
      margin-left: 8px;
    }
  }
}

.download-box {
  .download-item {
    width: 90px;
    height: 32px;
    padding: 0px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
      background: rgba(255, 248, 232, 1);
      color: rgba(230, 125, 0, 1);
    }
  }
}
.smartphone, .smartphone-hover {
  width: 12px !important;
  height: 12px !important;
}

.smartphone-hover {
  display: none;
}
.phone-item {
  &:hover {
    color: rgba(230, 125, 0, 1);
    .smartphone {
      display: none;
    }
    .smartphone-hover {
      display: inline-block;
    }
  }
}

.code-container {
  width: 256px;
  height: 150px;
  background-color: #fff;
  z-index: 999;
  top: 80px;
  right: 8px;
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
  .code-content {
    flex: 1;
    text-align: center;
    .code-title {
      color:  rgba(0, 0, 0, 0.90);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  img {
    width: 100px;
    height: 100px;
  }
}
</style>
