<template>
  <div class="home">
    <div class="search-bar" :class="showBoxShadow && 'box-shadow'">
      <SearchBar style="width: 1200px;margin: 0 auto; " :licence="true" :keyword="searchKeywords" :below-keyword="belowKeyword" />
    </div>
    <div style="background: #fff;">
      <allProductClass />
    </div>
    <div class="home-content">
      <div class="content-container">
        <!-- 兼容老数据 -->
        <Menu :data="bannerData?.props?.bannerLeft" :style="{ visibility : data ? 'visible' : 'hidden' }" />
        <Center :data="bannerData" />
        <div class="content-right">
          <User :data="bannerData?.props?.bannerRight || []" />
        </div>
      </div>
      <VPageCouplet v-if="pageCouplet" :options="pageCouplet" />
    </div>
    <!-- 电梯导航 -->
    <VLiftNav class="v-lift-nav-ref" :options="liftNavData?.props" auto-top />

    <div class="cms-config">
      <template v-if="list.length !== 0">
        <template v-for="item in list">
          <VCategoryCard v-if="item.type== 'v-category-card'" ref="v-category-card" :key="item.id" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <VPoster v-if="item.type== 'v-poster'" :id="item.id" ref="v-poster" :key="item.id" class="cms-config-item" :options="item.props" :class="setMarginClass(item.props)" @load="handelLoads" />
          <vManyProduct v-if="item.type== 'v-many-product'" ref="v-many-product" :key="item.id" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <VProductCard v-if="item.type== 'v-product-card'" ref="v-product-card" :key="item.id" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <VFloor v-if="item.type== 'v-floor'" :key="item.id" ref="v-floor" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <vHotZone v-if="item.type== 'v-hot-zone'" :id="item.id" ref="v-hot-zone" :key="item.id" class="cms-config-item" :options="item.props" :class="setMarginClass(item.props)" @load="handelLoads" />
          <vTitle v-if="item.type== 'v-title'" :id="item.id" :key="item.id" ref="v-title" class="cms-config-item" :options="item.props" @load="handelLoads" />
          <vManyText v-if="item.type== 'v-many-text'" :id="item.id" ref="v-many-text" :key="item.id" class="cms-config-item" :options="item.props" @load="handelLoads" />
          <vTogetherGroup v-if="item.type== 'v-together-group'" ref="v-together-group" :key="item.id" :cms-id="cmsId" class="cms-config-item" :options="item" @load="handelLoads" />
          <vMarket v-if="item.type== 'v-market'" :key="item.id" ref="v-market" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <vPriceDown v-if="item.type== 'v-price-down'" :key="item.id" ref="v-price-down" :cms-id="cmsId" class="cms-config-item" :options="item" @load="handelLoads" />
          <vHotProductRecommend v-if="item.type== 'v-hot-product-recommend'" :key="item.id" ref="v-hot-product-recommend" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
        </template>
      </template>
      <template v-else>
        <div class="cms-config-empty">
          <div class="cms-config-wrap">
            <img src="@/assets/images/home/empty.png">
            <p>暂无相关内容</p>
          </div>
        </div>
      </template>
    </div>
    <div v-if="upLoading" class="up-loading">
      <div class="up-loading-content">
        <Spin fix>
          <Icon type="ios-loading" size="30" class="demo-spin-icon-load" />
        </Spin>
      </div>
    </div>
    <div v-if="showFooter" class="home-footer">
      <ShopDescription />
      <HomeHelper />
    </div>
    <CheckQualifications ref="checkQualification" />
    <Pop />
    <ExchangePopup
      :visible.sync="showExchangePopup"
      :exchange-popup-obj="exchangePopupObj"
      @showModal="val=>showExchangeModal=val"
    />
    <ExchangeModal
      :visible.sync="showExchangeModal"
      :title="exchangePopupObj.lastMsgTitle"
      :qualification-records-id="exchangePopupObj?.msgExp?.qualificationRecordsId"
      :customer-id="exchangePopupObj?.msgExp?.customerId"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHelper from '@/components/home/HomeHelper'
import ShopDescription from '@/components/home/ShopDescription'
import { getDecorateData, electronExchangeagreeFalg } from '@/common/service/home'
import { zhugeBrowsePageTrack } from '@/common/utils/base'
import SearchBar from '@/components/SearchBar.vue'
import Menu from '@/views/home/coms/menu.vue'
import Center from '@/views/home/coms/center.vue'
import User from '@/views/home/coms/user.vue'
import VCategoryCard from './coms/vCategoryCard/index.vue' // 分类卡片
import VPoster from './coms/vPoster/index.vue' // 海报
import vManyProduct from './coms/vManyProduct/index.vue' // 多商品
import VPageCouplet from './coms/vPageCouplet/index.vue' // 页联
import VLiftNav from './coms/vLiftNav/index.vue' // 电梯导航
import VProductCard from './coms/vProductCard/index.vue' // 电梯导航
import VFloor from './coms/vFloor/index.vue' // 楼层
import vHotZone from './coms/vHotZone/index.vue' // 热区
import vTitle from './coms/vTitle/index.vue' // 标题
import vManyText from './coms/vManyText/index.vue' // 多行文本
import allProductClass from './coms/allProductClass.vue' // 全部商品分类
import vTogetherGroup from './coms/vTogetherGroup/index.vue' // 拼团活动
import vMarket from './coms/vMarket/index.vue' // 拼团活动
import vPriceDown from './coms/vPriceDown/index.vue' // 润划算活动
import vHotProductRecommend from './coms/vHotProductRecommend/index.vue' // 商家爆品推荐
import CmsDialogMixin from '@/common/mixins/cmsDialog'
import CheckQualifications from './coms/checkQualifications'
import { getToken, getStorageSync, removeStorageSync, getLoginMobile } from '@/common/utils'

import { getPollingTime } from '@/common/utils/aptitudePolling'
import Pop from '@/views/home/coms/Pop/Pop.vue'

import _ from 'lodash'
import { Spin } from 'view-design'
import ExchangePopup from '@/components/OpenAccountExchange/ExchangePopup.vue'
import ExchangeModal from '@/components/OpenAccountExchange/ExchangeModal.vue'
import { mapGetters } from 'vuex'

const loadingKey = [ // CMS动态组件枚举,新增注意添加
  'v-category-card',
  'v-poster',
  'v-many-product',
  'v-product-card',
  'v-floor',
  'v-hot-zone',
  'v-title',
  'v-many-text',
  'v-together-group',
  'v-market',
  'v-price-down',
  'v-hot-product-recommend'
]
export default {
  name: 'Home',
  components: {
    HomeHelper,
    ShopDescription,
    SearchBar,
    Menu,
    Center,
    CheckQualifications,
    allProductClass,
    User, VCategoryCard, VPoster, vManyProduct, VPageCouplet, VLiftNav, VProductCard, VFloor, vHotZone, vTitle, vManyText,
    vTogetherGroup, vMarket, vPriceDown, vHotProductRecommend,
    Pop,
    Spin, ExchangePopup, ExchangeModal
  },
  mixins: [CmsDialogMixin],
  data() {
    return {
      data: null,
      cmsId: '',
      cmsDialogId: '', //  装修id，给cms dialog使用
      dialogCode: 'app_home', // 给dialoa使用
      showBoxShadow: false,
      showFooter: false,
      list: [],
      compareList: [],
      upLoading: false,
      cmsListStart: [],
      cmsListLast: [],
      screeLoadStart: false,
      loadNum: 0,
      // 是否显示开户电子资料交换弹窗
      showExchangePopup: false,
      showExchangeModal: false,
      exchangePopupObj: {},
      loadTime: 0
    }
  },
  computed: {
    ...mapGetters({
      customerInfo: 'common/getCustomerInfo'
    }),
    bannerData() {
      const bannerData = this.data?.items?.find((item) => item.type === 'v-banner')
      return bannerData
    },
    refresh() { return this.$store.state.global.refreshHome },
    liftNavData() { // 电梯导航
      return this.data?.items?.find((item) => item.type === 'v-lift-nav')
    },
    pageCouplet() {
      return this.data?.items?.find((item) => item.type === 'v-page-couplet')?.props
    },
    // cms配置的搜索框数据
    searchKeywords() {
      const searchData = this.data?.items?.find((item) => item.type === 'v-search')?.props
      if (searchData) {
        // eslint-disable-next-line eqeqeq
        if (searchData.styleType == '1') {
          return searchData.searchPlaceholder
        // eslint-disable-next-line eqeqeq
        } else if (searchData.styleType == '2') {
          if (searchData?.keywords?.length > 1) {
            return searchData.keywords
          } else if (searchData?.keywords?.length === 1) {
            return searchData.keywords.at(0)
          }
          return ''
        }
      }
      return ''
    },
    belowKeyword() {
      const searchData = this.data?.items?.find((item) => item.type === 'v-search')?.props
      return searchData?.belowKeyword
    },
    counte() {
      return this.data?.items || []
    }
  },
  watch: {
    refresh() {
      // 执行首页更新回调
      this.init()
    }
  },
  mounted() {
    this.showBoxShadow = false
    setTimeout(() => {
      this.$bus.$emit('setFooterBar', false)
    }, 100)
    setTimeout(() => {
      this.checkShowExchangePopup()
    }, 2000)
  },
  created() {
    this.$zhuge && this.$zhuge.track('首页-进入首页')
    this.init()
  },
  methods: {
    async init() {
      this.loadTime = new Date().getTime()
      const { data } = await getDecorateData() || {}
      if (data?.items) {
        // eslint-disable-next-line eqeqeq
        data.items = data.items.filter(item => item.type != 'children_position')
      }
      this.data = data
      this.cmsId = (data && data.id || '').toString()
      this.cmsDialogId = this.cmsId
      if (getToken()) {
        setTimeout(() => {
          // 如果等于1,代表从登陆页过来的，需要调用校验首页资质过期接口
          if (getStorageSync('isLoginCome') === 1) {
            this.$refs.checkQualification.check()
            removeStorageSync('isLoginCome')
          }
        }, 100)
      }
      const items = data?.items || []

      // CMS 空数据状态逻辑
      if (items.length === 0) {
        this.screeLoadStart = true
        this.handelShowFooter()
      } else {
        const loadData = []
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          if (loadingKey.includes(item.type)) {
            loadData.push(item)
          }
        }
        if (loadData.length === 0) {
          this.screeLoadStart = true
          this.handelShowFooter()
          return
        }
      }

      this.loadNum = 0
      const resetTop = (back) => {
        setTimeout(() => {
          document.documentElement.scrollTop = 0
          document.body.scrollTop = 0
          back()
        }, 100)
      }
      resetTop(() => {
        window.removeEventListener('scroll', _.throttle(this.scrollHandler, 10))
        window.addEventListener('scroll', _.throttle(this.scrollHandler, 200))
        this.screenLoad()
      })
      this.$nextTick(() => {
        const time = new Date().getTime()
        this.loadTime = time - this.loadTime
        if (this.loadTime && this.$zhuge) {
          zhugeBrowsePageTrack(this.$zhuge, {
            '页面标题': '首页',
            '页面请求数量': 30,
            '页面加载时长': this.loadTime,
            'page_url': this.$route.path
          })
          this.loadTime = 0
        }
      })
    },
    // CMS模版加载回调
    handelLoads(row) {
      const rowKey = row.type || ''
      for (let i = 0; i < this.compareList.length; i++) {
        const key = this.compareList[i]
        if (key === rowKey) {
          this.compareList.splice(i, 1)
          this.cmsListLast.push(rowKey)
        }
      }

      console.log(this.compareList)
      // 删除完毕可以进行重新加载
      if (this.compareList.length === 0) {
        this.$nextTick(() => {
          setTimeout(() => {
            // 关闭loading
            this.upLoading = false
            setTimeout(() => {
              // 判断滚动条位置 没到 打开 加载开关, 等待上拉到底 进行加载
              this.screeLoadStart = false
              // 到底了自动执行加载
              if (this.handelCountLoadScroll()) {
                this.screenLoad()
              }
              // 判断一下 滚动条位置 如果距离底部少于 50px 则进行加载
              // this.screeLoadStart = false
              // // 处理弱网情况滚动条直接卡死问题
              // const top = document.documentElement.scrollTop
              // const countTop = document.body.scrollHeight - document.body.clientHeight - top
              // if (countTop <= 10 && this.loadPageNum < this.data?.items?.length) {
              //   this.screenLoad()
              // }
              // // 回调数据等于统计数据显示尾部导航
              // if (this.cmsListLast.length === this.cmsListStart.length) {
              //   this.handelShowFooter()
              // }
            }, 100)
          }, 1000)
        })
      }
    },
    // 根据边距设置样式
    setMarginClass(data) {
      let classStr = ''
      const marginType = data.marginType || []
      if (marginType.includes('1')) {
        classStr += ' no-top'
      }
      if (marginType.includes('2')) {
        classStr += ' no-bottom'
      }
      return classStr
    },
    // 滚动加载
    scrollHandler(e) {
      const top = e.target?.scrollingElement?.scrollTop || 0
      if (top > 32) {
        this.showBoxShadow = true
      } else {
        this.showBoxShadow = false
      }
      if (this.handelCountLoadScroll()) {
        this.screenLoad()
      }
    },

    handelCountLoadScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight || 0
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight || 0
      return scrollTop + clientHeight >= scrollHeight - 50
    },
    handelShowFooter() {
      // 显示底部footer
      this.showFooter = true
      setTimeout(() => {
        this.$bus.$emit('setFooterBar', true)
      }, 100)

      // 关闭loading
      this.upLoading = false
    },
    screenLoad(data = {}) {
      // 加载节奏控制变量
      if (this.screeLoadStart) return
      const scNum = 4
      // 加载数量测试
      this.screeLoadStart = true
      // loading 开启
      this.upLoading = true
      // 控制节奏变量
      const allList = this.data?.items || []

      const loadList = allList.slice(this.loadNum, this.loadNum + scNum)

      this.loadNum += scNum

      this.list.push(...loadList)
      const compareList = []
      if (loadList.length !== 0) {
        // 当前匹配加载项
        for (let i = 0; i < loadList.length; i++) {
          const item = loadList[i]
          if (loadingKey.includes(item.type)) {
            compareList.push(item.type)
          }
        }
        this.compareList = compareList
        return
      }
      this.handelShowFooter()
    },

    // 判断是否显示电子资料交换弹窗
    async checkShowExchangePopup() {
      const { code, data } = await electronExchangeagreeFalg()
      // 判断显示修改密码弹窗或者显示电子资料交换弹窗
      if (code === 200) {
        if (data === 0) {
          const phone = getLoginMobile()
          this.$Modal.warning({
            title: '登录提示',
            content: '您好，系统检测到您当前未设置密码，存在安全风险。为了保障您的账户安全，请您立即设置密码。',
            okText: '立即设置',
            onOk: () => {
              this.$router.push('/user/forgetPwd?username=' + phone)
            }
          })
        }
        if (data && data[0]?.lastMsgTitle) {
          this.showExchangePopup = true
          this.exchangePopupObj = data[0]
        }
      }
    }

  }
}
</script>
<style scoped lang="less">
.home-footer {
  background: #fff;
}
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
.home-content {
  margin: 0 auto 12px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 0 15px 0px 15px;
  overflow: hidden;
  position: relative;
  .content-container {
    display: flex;
    // align-items: center;
    // justify-content: center;
    width: 1200px;
    // background: #F1F1F1;
    border-radius: 8px;
    margin-top: 12px;
    // height: 552px;
    // padding: 16px;
  }
}
.content-right {
  width: 260px;
  height: max-content;
  // margin-top: 38px;
}
.cms-config {
  position: relative;
  overflow: hidden;
  clear: both;
  font-size: 0;
  padding: 0 calc((100% - 1920px) / 2 + 100px) 32px;
}
.cms-config-item {
  display: inline-block;
  vertical-align: middle;
  min-width: 1200px;
  margin: 12px 0 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &:first-child {
    margin-top: 0;
  }
  &.no-top {
    margin-top: 0;
  }
  &.no-bottom {
    margin-bottom: 0;
  }
}
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cms-config-wrap {
    width: 216px;
    height: 216px;
    position: relative;
  }
  img {
    width: 216px;
    height: 216px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
}
// .v-lift-nav-wrap {
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100%;
  .v-lift-nav-ref {
    //position: sticky;
    //bottom: 0;
    //right: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 20px;
    margin:auto;
    height: fit-content;
  }
.up-loading {
  width: 100%;
  .up-loading-content {
    margin: 0 auto;
    width: 100px;
    height: 200px;
    position: relative;
    text-align: center;
    font-weight: 600;
  }
}
.demo-spin-col .circular {
        width:25px;
        height:25px;
    }
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    ::v-deep .ivu-spin-fix {
      background: none;
    }
</style>
