<template>
  <Modal
    v-model="showModal"
    title="联系商家"
    :width="420"
    :footer-hide="true"
    @on-cancel="handleCancel"
  >
    <div class="contact-modal">
      <div v-if="shopData.servicePhone" class="contact-item">
        <div class="contact-item-title">商家客服：</div>
        <div class="contact-item-content">{{ shopData.servicePhone || '-' }}</div>
        <div v-if="isPhoneNum(shopData.servicePhone)" class="contact-item-btn" @click="handleCopy(shopData.servicePhone)">复制</div>
      </div>
      <div v-if="shopData.salerPhone" class="contact-item">
        <div class="contact-item-title">业务经理({{ shopData.salerName || '-' }}):</div>
        <div class="contact-item-content">{{ shopData.salerPhone || '-' }}</div>
        <div v-if="isPhoneNum(shopData.salerPhone)" class="contact-item-btn" @click="handleCopy(shopData.salerPhone)">复制</div>
      </div>
      <div v-if="shopData.drawerPhone" class="contact-item">
        <div class="contact-item-title">开票员({{ shopData.drawerName || '-' }}):</div>
        <div class="contact-item-content">{{ shopData.drawerPhone || '-' }}</div>
        <div v-if="isPhoneNum(shopData.drawerPhone)" class="contact-item-btn" @click="handleCopy(shopData.drawerPhone)">复制</div>
      </div>
      <div v-if="!shopData.servicePhone && !shopData.salerPhone && !shopData.drawerPhone" class="contact-item-empty">暂无商家电话</div>
    </div>
  </Modal>
</template>

<script>
import { copy } from '@/common/uni.js'
export default {
  name: 'ShopContact',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shopData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.showModal = val
      }
    }
  },
  methods: {
    isPhoneNum(str) {
      return /^(0\d{2,3}-?\d{7,8})|(1[3-9]\d{9})$/.test(str)
    },
    async handleCopy(text) {
      try {
        this.$zhuge.track('客服-点击客服按钮', {
          '店铺名称': this.shopData?.orgAbbr,
          '客服类型': '店铺客服'
        })
        await copy(text)
      } catch (error) {
        console.error('复制到剪贴板失败:', error)
      }
    },
    handleCancel() {
      this.showModal = false
      this.$emit('update:visible', false)
    }
  }
}
</script>

  <style lang="less" scoped>
  .contact-modal{
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        &-title, &-content{
            color:rgba(0, 0, 0, 0.9);
            font-size: 14px;
        }
        &-content{
            padding: 0 8px;
        }
        &-btn{
            color: rgba(56, 83, 226, 1);
            font-size: 14px;
            cursor: pointer;
        }
    }
  </style>
