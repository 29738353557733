import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'

/**
 * 获取页面装修数据接口
 * type: 'product' | 'applets'
 */
export const getDecorateData = () => {
  return get({
    url: `${api.baseUrl}/wx/page/getByCode?pageTypeCode=pc_home&clientTypeCode=pc&districtCode=410101`
  })
}

/**
 * 获取商家首页装修数据
 *
 */
export const getmerchantData = (id) => get({
  url: `${api.baseUrl}/wx/store/getByCode?clientTypeCode=merchant&pageTypeCode=merchant_home&businessId=${id}`
})
/**
 * 获取商家基础信息
 */
export const getBusinessBaseInfo = (id) => get({
  url: `${api.baseUrl}/org/info/simple?orgId=${id}`
})

/**
 * 获取资质信息图片
 */
export const getCertifiPics = (orgId) => get({
  url: `${api.baseUrl}/org/orgcertpic/list?orgId=${orgId}`
})
/**
 * 判断是否已建立建采关系
 */
export const getProcurementRelation = (param) => post({
  url: `${api.baseUrl}/cust/custuserrel/getCustRelByCustIdOrgId`,
  data: param
})
/**
 * 获取商家公告
 */
// export const getNoticeOfBusiness = (id) => get({
//   url: `${api.baseUrl}/org/info/notice?orgId=${id}`
// })
/**
 * 商家和客户建立建采关系
 */
export const buildProcurementRelation = (param) => post({
  url: `${api.baseUrl}/cust/custuserrel/buildCustRelByCustIdOrgId`,
  data: param
})

/* 取消订单
* @param orderSn 订单编号
* @param reason   取消原因
*/
export const cancelOrder = (orderSn, reason) => post({
  url: `${api.baseUrl}/order/order/${orderSn}/cancel`,
  data: reason,
  header: { 'content-type': 'application/x-www-form-urlencoded' },
  isLoading: true
})
/**
 * 获取店铺信息
 */
export const getShopDetail = () => get({
  url: `${api.baseUrl}/store/store/get/detail`,
  isLoading: true
})

/**
 * 根据ids获取商品集合
 */
export const getProductByIdS = (data) => {
  return post({
    url: `${api.baseUrl}/prd/product/listByPrdIdsAndActiveIds`,
    data
  })
}

/**
 * 根据标品ids获取标品集合
 */
export const getStandandByIdS = (data = { ids: [] }) => {
  return post({
    url: `${api.baseUrl}/prd/product/basePrd/listById`,
    data
  })
}

/**
* 商家首页，根据ids获取商品集合
*/
export const getSignData = (id) => {
  return get({
    url: `${api.baseUrl}/wx/sign/findSignList?businessId=${id}`
  })
}

/**
* 商家首页，根据ids获取商品集合
	废弃
*/
export const getCommodityIdS = (ids) => {
  return post({
    url: `${api.baseUrl}/prd/product/listByPrdIdsAndActiveIds`,
    data: ids
  })
}

/**
 * ES查询接口
 *
 入参{
	"activityState(活动状态 0 无 1有)": 0,
	"baseProductId(标品id)": [
		0
	],
	"brandId": [
		0
	],
	"categoryId(商品分类)": 0,
	"customerTypeId(客户类型id)": 0,
	"id(文档id)": "string",
	"keyword(关键字)": "string",
	"manufactureCompanyIds(厂家ID)": [
		0
	],
	"orgId(店铺id，搜索店铺商品的时候使用)": 0,
	"page": {
		"notConvert": true,
		"order": "string",
		"pageNumber": 0,
		"pageSize": 0,
		"records": [
			{}
		],
		"sort": "string",
		"total": 0
	},
	"productActivityTypeMappings(活动商品映射集合)": [
		{
			"activityType(活动类型 1满减满折 2润划算商品)": "string",
			"productId(商品id)": 0
		}
	],
	"productId(商品id)": 0,
	"region(区域编码)": 0,
	"state(是否有货 0.无库存 1.有库存)": 0,
	"suggestWord": {
		"keywords": "string",
		"point": 0
	},
	"uniSpec(包装规格)": [
		"string"
	]
}
 */
export const getProductByEs = (data = {}) => {
  return post({
    url: `${api.baseUrl}/prd/product/listProductByEs`,
    data: data
  })
}

/**
 * 获取装修版本
 * pageTypeCode
 * clientTypeCode
 * businessId
 */
export const getPageVersion = (data = {}) => {
  return get({
    url: `${api.baseUrl}/wx/store/getPageVersion?clientTypeCode=app&pageTypeCode=app_home`
  })
}
/**
 * 获取最新公告数据，默认最近三条
 * @param {} data
 * @returns
 */
export const getRecent = (data = { size: 3 }) => {
  return get({
    url: `${api.baseUrl}/wx/notice/getRecent`,
    data: data
  })
}

// 协议相关-微信小程序-首页：验证和获取最新协议
export const verifyLatestAgreement = () => {
  return get({
    url: `${api.baseUrl}/agr/verifyLatestAgreement`
  })
}
/*
* 关于我们资质信息
 */
// 资质信息
export const getAgreementList = (data) => {
// 后端来回改参数
  const tempData = {
    sourceType: 'PC',
    fileType: 'ALL'
  }
  return post({
    url: `${api.baseUrl}/wx/page/findAgreeMentList`,
    data: tempData || data
  })
}
// 资质详情
export const getAgreementDetail = (id) => {
  return post({
    url: `${api.baseUrl}/wx/page/getAgreeMentDetails?id=${id}`
  })
}

// 协议相关-微信小程序-首页：签署协议
export const signAgreement = (data) => {
  return post({
    url: `${api.baseUrl}/agr/signAgreement`,
    data: data
  })
}
// 店铺首页 单独接口获取 tab 数据
export const getWxNavigationTabs = (id) => {
  return get({
    url: `${api.baseUrl}/wx/store/getWxNavigationTabs?businessId=${id}`
  })
}
// 店铺首页 获取 其他导航页 专题/活动页 装修数据
export const getWxTabsDetails = (id) => {
  return get({
    url: `${api.baseUrl}/wx/store/getWxTabsDetails?pageId=${id}`
  })
}
// 获取未读消息数
export const getNotityUnread = () => {
  return get({
    url: `${api.baseUrl}/userMessageInbox/getAllUnreadMsgCount`
  })
}
// 根据企业id获取收货地址列表
export const getAddrListByCustomerId = (id) => {
  return get({
    url: `${api.baseUrl}/cust/custreceiveaddr/getAddrListByCustomerId?customerId=${id}`
  })
}

// 切换收货地址
export const switchReceiveAdd = (id) => {
  return post({
    url: `${api.baseUrl}/v1/wx/switchReceiveAdd?id=${id}`
  })
}

/**
 *  获取资源发布状态
 *
 */
export const getPublicStatus = (data) => {
  return get({
    url: `${api.baseUrl}/wx/store/getWxOrderStatus`,
    data
  })
}

// 判断客户是否被商家加入黑名单
export const judegJoinBlacklist = (orgId) => {
  return get({
    url: `${api.baseUrl}/cust/custuserrel/isBlackListFlag?orgId=${orgId}`
  })
}

// 获取cms dialog 配置数据
export const getCmsDialogConfig = (code, id) => {
  return get({
    url: `${api.baseUrl}/wx/page/getWxSetting?id=${id}&code=${code}&type=pc`
  })
}

// 小程序端-登陆成功后-主动拉取弹框内容消息  资质过期
export const pullAll = () => {
  return get({
   url: `${api.baseUrl}/cust/custuser/getMyCustomerCertExpireTag`
  })
}

// 小程序端-登陆态下，轮询拉取过期的必要资质
export const pullAllPolling = () => {
  return get({
    url: `${api.baseUrl}/userPopupMessage/pull/expired/qualification`
  })
}
//  小程序端-登陆态下，必要资质提醒设置
export const popSetQualification = (data) => {
  return post({
    url: `${api.baseUrl}/userPopupMessage/popSet/qualification`,
    data: data
  })
}

// 更新弹窗弹出次数
export const updateCmsDialogNum = (data) => {
  return post({
    url: `${api.baseUrl}/wx/page/updatePopCount`,
    data: data
  })
}

// 小程序端-登陆态下，我的企业 拉取过期的必要资质
export const qualificationMy = () => {
  return get({
    url: `${api.baseUrl}/userPopupMessage/pull/expired/qualification/my`
  })
}

// 获取PC专区tab配置数据
export const getPcTabData = () => {
  return get({
    url: `${api.baseUrl}/wx/config/getConfigByCode?code=pc_tab`
  })
}

// 获取抽奖活动
export const getShopLottery = (orgId) => {
  return post({
	  url: `${api.baseUrl}/lottery/customer/lotteryQueryByOrg?orgId=${orgId}`
  })
}

/**
 * 根据token获取我的资质
 * @returns
 */
export const getMyCertificateListAPI = () => get({
  url: `${api.baseUrl}/cust/custuser/getMyCertificateList`
})

/**
 * pc/小程序提交变更申请接口（新增）
 */
export const changeCustomType = (data) => post({
  url: `${api.baseUrl}/customer/approvalcust/changeCustomType`,
  data: { ...data, source: 'user' }
})
// 报名
export const customerSignUp = (data) => {
  return post({
    url: `${api.baseUrl}/signUp/customer/customerSignUp`,
    data
  })
}
// 获取弹窗数据
export const getPropsData = (data) => {
  return get({
    url: `${api.baseUrl}/cms/cmsgeneralsetting/getSetting`,
    data
  })
}
// 获取弹窗任务执行结束执行保存
export const savePropsData = (data) => {
  return get({
    url: `${api.baseUrl}/cms/cmsgeneralsetting/saveSetting`,
    data
  })
}

// 开户电子交换-弹窗标识
export const electronExchangeagreeFalg = () => {
  return get({
    url: `${api.baseUrl}/cust/custuser/electronExchangeagreeFalg`
  })
}

// 开户电子交换-查询资质列表
export const electronExchangeCertList = (customerId, qualificationRecordsId) => {
  return get({
    url: `${api.baseUrl}/cust/custuser/electronExchangeCertList?customerId=${customerId}&qualificationRecordsId=${qualificationRecordsId}`
  })
}
// 开户电子交换-同意交换
export const electronExchange = (data) => {
  return get({
    url: `${api.baseUrl}/cust/custuser/electronExchange`,
    data
  })
}
// 开户电子交换-取消交换
export const electronExchangeagreeSetFalg = (data) => {
  return get({
    url: `${api.baseUrl}/cust/custuser/electronExchangeagreeSetFalg`,
    data
  })
}

// 开户电子交换-获取协议内容
export const electronExchangeAgreement = (customerId) => {
  return get({
    url: `${api.baseUrl}/agr/electronExchangeAgreement`
  })
}

// 商品信息分类合并接口
export const obtainClassification = (data) => {
  return post({
    url: `${api.baseUrl}/prd/product/obtainClassification`,
    data
  })
}

// 获取电子首营委托信息
export const certfirstbusrelQueryDetailById = (data) => {
  return get({
    url: `${api.baseUrl}/cust/certfirstbusrel/queryDetailById`,
    data
  })
}

