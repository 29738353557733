<template>
  <div>
    <!-- 开户电子资料交换申请弹窗 -->
    <Modal v-model="showModal" width="818" height="416" :closable="false" :footer-hide="true" class-name="exhange-modal">
      <Spin v-show="loading" fix>加载中...</Spin>
      <div v-if="!loading" class="exhange-modal-wrap">
        <div class="padding-lr">
          <div class="title-box">
            <span class="title-text">{{ title }}</span>
            <Icon type="md-close" size="24" color="#4B4B4B" style="cursor: pointer;" @click="close" />
          </div>
          <div class="org-name">
            {{ certObj.customName }}
          </div>
          <div class="label">申请资质：</div>
          <div class="pics-box">
            <div v-for="(item, index) in certObj.pics" :key="index" class="pic-item">
              <swiper
                v-if="item.picUrls && item.picUrls.length > 1"
                :ref="'mySwiper' + key"
                :options="swiperOption"
              >
                <swiper-slide v-for="p in item.picUrls" :key="p.id">
                  <img :src="p" alt="">
                </swiper-slide>
              </swiper>
              <img v-else :src="item.picUrls.length === 1 ? item.picUrls[0] : require('@/assets/images/home/default-pic.png')" alt="">
              <div class="pic-name">{{ item.name }}</div>
            </div>
          </div>

          <!-- <div class="platform-wrap">
            <span class="label">交换平台：</span>
            <RadioGroup v-model="platform">
              <Radio v-for="item in platformOptions" :key="item.value" :label="item.value">{{ item.label }}</Radio>
            </RadioGroup>
          </div> -->
        </div>

        <div class="btn-wrap">
          <div class="agree">
            <Checkbox v-model="btChecked">我已仔细阅读并同意</Checkbox>
            <span v-for="item in agreement" :key="item.agrId" class="agree-content" @click="goToDetail(item.agrId)">{{ '《' + item.agrName + '》' }}</span>
          </div>
          <div class="btn-box">
            <Button type="default" @click="cancel">取消</Button>
            <Button
              type="primary"
              :class="['agree-btn', (!btChecked || btnLoading) && 'agree-btn-disabled' ]"
              :loading="btnLoading"
              :disabled="!btChecked || btnLoading"
              @click="agree"
            >同意</Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { electronExchangeCertList, electronExchange, electronExchangeagreeSetFalg, electronExchangeAgreement } from '@/common/service/home'
import { getFilePath } from '@/common/utils/base'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { forEach } from 'lodash'
import { mapGetters } from 'vuex'
import { debounce } from '@/common/utils'

const platformOptions = [{ label: '供应商一', value: 'hk' }, { label: '供应商二', value: 'gx' }, { label: '供应商三', value: 'hy' }]

export default {
  name: '',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    qualificationRecordsId: {
      type: String,
      default: ''
    },
    customerId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      certObj: {},
      showModal: false,
      btChecked: false,
      loading: false,
      swiperOption: {
        // 分页器设置
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        notNextTick: true,
        // 循环
        loop: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        // grabCursor : true,
        // 滑动之后回调函数
        on: {
          slideChangeTransitionEnd: function() {
            // console.log(this.activeIndex);//切换结束时，告诉我现在是第几个slide
          }
        }

      },
      agreement: [],
      platformOptions,
      platform: 'gx',
      btnLoading: false
    }
  },
  computed: {

  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.showModal = newVal
        this.getCertAndAgreement()
      }
    }
  },
  created() {
  },
  methods: {
    getFilePath,

    async getCertAndAgreement() {
      this.loading = true
      Promise.all([electronExchangeAgreement(), electronExchangeCertList(this.customerId, this.qualificationRecordsId)]).then(res => {
        const [agreement, cert] = res
        if (agreement.code === 200) {
          this.agreement = agreement.data
        }
        if (cert.code === 200) {
          const data = cert.data
          data.pics = []
          forEach(data.certNameAndPic, (pics, name) => {
            const pic = {
              name,
              picUrls: pics.map((p) => p.thumbnailUrl).filter(Boolean)
            }
            data.pics.push(pic)
          })
          this.certObj = data
        }
      })
      this.loading = false
    },
    close() {
      this.showModal = false
      this.$emit('update:visible', this.showModal)
    },

    cancel: debounce(async function() {
      const { code } = await electronExchangeagreeSetFalg({ customerId: this.customerId, type: this.platform })
      if (code === 200) {
        this.close()
      }
    }, 1000),

    agree: debounce(async function() {
      this.btnLoading = true
      const { code } = await electronExchange({ customerId: this.customerId, type: this.platform, qualificationRecordsId: this.qualificationRecordsId })
      if (code === 200) {
        this.btnLoading = false
        this.close()
      }
    }, 1000),

    goToDetail(agrId) {
      window.open(`/user/exchangeAgreement?agrId=${agrId}`, '_blank')
    }
  }
}
</script>
<style scoped lang="less">
::v-deep .exhange-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
    .ivu-modal-content {
      .ivu-modal-body {
        padding: 0 !important;
        .exhange-modal-wrap {
          padding-top: 20px;

          .padding-lr{
            padding-left: 24px;
            padding-right: 24px;
          }
          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .title-text {
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .org-name {
            font-size: 18px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            margin-bottom: 16px;
          }
          .label {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
          .pics-box {
            margin-top: 8px;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            max-height: 402px;
            overflow: auto;
            margin-bottom: 24px;
            .pic-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              width: 160px;
              padding: 20px 8px 16px 8px;
              border-radius: 4px;
              border: 1px solid #e7e7e7;
              // margin-bottom: 16px;
              img {
                width: 120px;
                height: 120px;
                margin-bottom: 8px;
                background-color: #eee;
              }
            }
            .swiper-container {
              width: 100%;
            }
          }
          .platform-wrap{
            display: flex;
            align-items: center;
            margin: 16px 0;
            .ivu-radio-wrapper{
              display: inline-block !important;
              margin-right: 16px;
              .ivu-radio{
                margin-right: 0;
              }
            }
          }
          .btn-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #FAFAFA;
            padding: 12px 24px;
            border-radius: 0px 0px 8px 8px;
          }
          .agree {
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;

            .ivu-checkbox-inner {
              border-radius: 4px;
            }

            .ivu-checkbox-wrapper {
              font-size: inherit;

              .ivu-checkbox {
                margin-right: 4px;
              }
            }

            .agree-content {
              color: #ce7720;
              line-height: 20px;
              cursor: pointer;
            }
          }
          .btn-box{
            display: flex;
            justify-content: flex-end;
            .agree-btn{
              margin-left: 12px;
            }
            .agree-btn-disabled{
              color: #fff;
              background-color: #FDDA9F;
            }
          }
        }
      }
      .ivu-modal-footer {
        text-align: center;
        font-size: 16px;
        color: #ce7720;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
  }
}
</style>
