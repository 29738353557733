<template>
  <div class="info">
    <ChangeEnterprise @changeCompany="changeCompany" />
    <!--我的订单-->
    <div class="box">
      <!-- <div class="caption" @click="toPage('/order/list', { state: 'un_auth'})">我的订单
        <span class="caption-right">
          更多
          <Icon type="ios-arrow-forward" />
        </span>
      </div> -->
      <div class="grid">
        <div v-for="item in orders" :key="item.name" class="grid-item grid-item-order bottom" @click="toPage('/order/list', { tab: item.tab},item.name)">
          <!-- <img :src="item.src"> -->
          <div v-if="getByState" class="count">{{ orderStatus[item.tag] || 0 }}</div>
          <div class="text-14" v-text="item.name" />
        </div>
        <div class="grid-item grid-item-order bottom" @click="toPage('/order/refund/index',false,'退货/售后')">
          <div v-if="getByState" class="count">{{ orderStatus.returnCount }}</div>
          <div class="text-14">退货/售后</div>
        </div>
        <div class="grid-item grid-item-order bottom" @click="toPage('/order/list', { state: 'un_auth'},'全部订单')">
          <img :src="allOrder">
          <div class="text-14">全部订单</div>
          <img class="order-line" src="../../../../assets/images/center/order-line.png" alt="">
        </div>
      </div>

      <OrderList></OrderList>
    </div>
    <!--我的商家-->
    <div class="box">
      <div class="caption" @click="toPage('/center/merchant', { state: 'success_auth'},'我的商家')">我的商家
        <span class="caption-right">
          查看详情
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <div class="grid">
        <div v-for="item in merchant" :key="item.name" class="grid-item merchat item-box grid-hover" @click="toPage('/center/merchant', { state: item.state},item.name)">

          <div v-if="getByState" class="count merchat">
            {{ merchantCount[item.tag] || 0 }}
          </div>

          <div class="text-14" v-text="item.name" />
        </div>
      </div>
    </div>
    <!--我的收藏-->
    <div class="box margin-bottom-20">
      <div class="caption" @click="toPage('/center/collection',false,'我的收藏')">
        我的收藏
        <span class="caption-right">查看详情
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <CenterProducts :data="collections" type="1" />
    </div>
    <!--我的足迹-->
    <div class="box margin-bottom-20">
      <div class="caption" @click="toPage('/center/footmark',false,'我的足迹')">
        我的足迹
        <span class="caption-right">查看详情
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <CenterProducts :data="footprints" type="2" />
    </div>
    <!--常购清单-->
    <div class="box margin-bottom-20">
      <div class="caption" @click="toPage('/center/purchaseList',false,'常购清单')">
        常购清单
        <span class="caption-right">查看详情
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <CenterProducts :data="purchaseList" type="3" />
    </div>
  </div>
</template>
<script>
import pay from '../../../../assets/center/pay.png'
import deliver from '../../../../assets/center/deliver.png'
import receiving from '../../../../assets/center/receiving.png'
import estimate from '../../../../assets/center/estimate.png'
import exchange from '../../../../assets/center/exchange.png'
import allOrder from '../../../../assets/images/center/order.png'
import payH from '../../../../assets/center/payH.png'
import deliverH from '../../../../assets/center/deliverH.png'
import receivingH from '../../../../assets/center/deliveredCountH.png'
import estimateH from '../../../../assets/center/commentingCountH.png'
import exchangeH from '../../../../assets/center/exchangeH.png'
import ChangeEnterprise from '../components/ChangeEnterprise.vue'
import CenterProducts from '../components/CenterProducts.vue'
import OrderList from '../components/OrderList.vue'
import { getorderStats } from '@/common/service/order'
import { countByStateAPI } from '@/common/service/insertCustInfo'
import { zhugeBrowsePageTrack } from '@/common/utils/base'
import { footmarkList, listCollectProductToEs, getLastBuyProductList, getSimpleMyCustomerInfo } from '@/common/service'
export default {
  name: 'Info',
  components: {
    ChangeEnterprise,
    CenterProducts,
    OrderList
  },
  data() {
    return {
      orders: [
        {
          name: '待付款',
          tag: 'payingCount',
          src: pay,
          hSrc: payH,
          tab: 'PAYING'
        },
        {
          name: '待发货',
          tag: 'deliveringCount',
          src: deliver,
          hSrc: deliverH,
          tab: 'DELIVERING'
        },
        {
          name: '待收货',
          tag: 'deliveredCount',
          src: receiving,
          hSrc: receivingH,
          tab: 'DELIVERED'
        },
        {
          name: '待评价',
          tag: 'commentingCount',
          src: estimate,
          hSrc: estimateH,
          tab: 'RECEIVED'
        }
      ],
      orderStatus: {
        payingCount: 0,
        deliveredCount: 0,
        deliveringCount: 0,
        returnCount: 0
      },
      merchant: [
        {
          name: '已建采',
          tag: 'successAuthNum',
          state: 'success_auth'
        },
        {
          name: '建采中',
          tag: 'unAuthNum',
          state: 'un_auth'
        },
        {
          name: '商家拒绝',
          tag: 'refuseAuthNum',
          state: 'refuse_auth'
        }
      ],
      merchantCount: {
        refuseAuthNum: 0,
        successAuthNum: 0,
        unAuthNum: 0
      },
      collections: [],
      footprints: [],
      purchaseList: [],
      customerInfo: null,
      exchange,
      exchangeH,
      allOrder,
      calRenderDuration:0
    }
  },
  computed: {
    getByState() {
      if (this.customerInfo) {
        if (this.customerInfo.state === 'un_auth') {
          return false
        }
        if (this.customerInfo.state === 'under_auth') {
          return false
        }
        if (this.customerInfo.state === 'auth') {
          return true
        }
      } else {
        return false
      }
      return false
    }
  },
  created() {
   
    this.getCustomerInfo()
    this.getOrderCount()
    this.countByState()
    this.getCollection()
    this.getFootmarkList()
    this.getPurchaseList()
  },
  mounted(){

  },
  methods: {
    // 切换企业获取订单、商家、收藏、足迹信息
    changeCompany() {
      this.getOrderCount()
      this.countByState()
      this.getCollection()
      this.getFootmarkList()
      this.getPurchaseList()
    },
    // 获取企业信息
    async getCustomerInfo() {
      const res = await getSimpleMyCustomerInfo()
      const { code, data } = res
      if (code === 200) {
        this.customerInfo = data
      }
    },
    // 获取订单信息
    async getOrderCount() {
      this.orderStatus = {
        payingCount: 0,
        deliveredCount: 0,
        deliveringCount: 0,
        returnCount: 0
      }
      const { code, data } = await getorderStats()
      if (code === 200) {
        const keys = ['PAYING', 'DELIVERED', 'DELIVERING', 'RETURN', 'COMMENTING']
        data.forEach((item) => {
          Object.keys(item).map((key) => {
            if (keys.includes(item[key])) {
              this.$set(
                this.orderStatus,
                item[key].toLowerCase() + 'Count',
                item.stateCount
              )
            }
          })
        })
      }
    },
    // 获取商家信息
    async countByState() {
      const { code, data } = await countByStateAPI()
      if (code === 200) {
        this.merchantCount = data
      }
    },
    // 获取我的收藏
    async getCollection() {
      try {
        const { code, data } = await listCollectProductToEs({
          page: {
            pageNumber: 1,
            pageSize: 4
          }
        })
        if (code === 200) {
          this.collections = data?.records
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    // 获取我的足迹
    async getFootmarkList() {
      try {
        this.calRenderDuration = new Date().getTime()
        const { code, data } = await footmarkList({
          pageNum: 1,
          pageSize: 4
        })
        if (code === 200) {
          this.footprints = data?.result?.records
          this.$nextTick(()=>{
            zhugeBrowsePageTrack(this.$zhuge,{
              '页面标题':'个人中心',
              '页面加载时长':new Date().getTime() - this.calRenderDuration,
              'page_url':'/center/info',
              '页面请求数量':11
            })
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    // 获取我的常购清单
    async getPurchaseList() {
      try {
        const { code, data } = await getLastBuyProductList({
          condition: {},
          pageNum: 1,
          pageSize: 4
        })
        if (code === 200) {
          data?.records.forEach(item => {
            item.prdProductEsVo.availableStock = item.availableStock
          })
          this.purchaseList = data?.records
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    /**
     * 根据 state 状态提示
     * coustomlist: null 为新注册用户
     * un_auth: 未认证; under_auth: 认证中; auth: 已认证
     * */
    tipByState() {
      if (this.customerInfo) {
        const state = this.customerInfo.state
        if (state === 'un_auth') {
          this.$message.warning('未认证，认证成功后方可访问该功能。')
          return false
        }
        if (state === 'under_auth') {
          this.$message.warning('正在认证中，认证成功后方可访问该功能。')
          return false
        }
        if (state === 'auth') {
          return true
        }
      } else {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>当前账号未关联企业，请先关联</p>',
          okText: '去关联',
          cancelText: '暂不关联',
          onOk: () => {
            this.$router.push('/affiliated')
          },
          onCancel: () => {
            // this.$message.info('Clicked cancel')
          }
        })
        return false
      }
    },

    toPage(path, other, name) {
      if (path === '/order/list' || path === '/center/merchant' || path === '/order/refund/index') {
        if (!this.tipByState()) return
      }
      let query = {}
      if (other) {
        query = { ...other }
      }
      this.$router.push({
        path,
        query
      })
    },
  }
}
</script>
<style lang="less">

.merchat{
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  font-family: "PingFang SC";
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 32px !important; /* 160% */
}
.info{


  .box{

    .caption{
      color: var(--font_black-1, rgba(0, 0, 0, 0.90));
      /* medium/14 */
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */

      .caption-right{
        color: var(--font_black-2, rgba(0, 0, 0, 0.60));
        /* regular/14 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
    .grid{
      .item-box{
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .text-14{
        color: var(--font_black-1, rgba(0, 0, 0, 0.90));
        /* regular/14 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}
.grid-item{
  &>img{
    width: 26px;
    height: 26px;
  }
}

.grid-hover{
  padding: 2px !important;
  &:hover {
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
}
.center .content-right .bottom img{
  border-radius: 50%;
  transition: background-color 0.5s ease-in;
}
.center .reBtn{
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 2px;

  border-radius: 4px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  color: var(--hbrand-6, #3853E2);
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.center .reBtn-68{
  width: 68px;
  height: 22px;
  padding: 0 8px;
  font-size: 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-4, #DCDCDC);
  background: var(--gray-1, #FAFAFA);

  color: var(--font_black-1, rgba(0, 0, 0, 0.90));

  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.center .add-btn{
  background: var(--gray-1, #FAFAFA);
  align-items: center;
  display: flex;
  padding-top: 2px;

  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--gray-4, #DCDCDC);
  background: var(--gray-1, #FAFAFA);

  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.grid-item-order {
  padding: 0 !important;
  cursor: pointer;
  transition: all .35s ease-in-out;
  position: relative;
  &:hover {
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
  .order-line {
    width: 15px;
    height: 52px;
    position: absolute;
    top: 0;
    left: -10px;
    border-radius: none !important;
  }
}
</style>
