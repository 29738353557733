<template>
  <div class="car">
    <SearchBar style="width: 1200px;margin: 0 auto;" @queryCart="queryCart" />
    <div class="car-content">
      <div class="car-box">
        <div class="total-address">
          <div class="total">
            <div class="total-big">我的购物车</div>
            <!-- <div class="total-num">（共20件）</div> -->
          </div>
          <div class="address">
            <img src="../../assets/images/car/location.png" alt="">
            <span v-if="Object.keys(customerObj).length > 0">配送至：{{
              customerObj.receiveDistrict.provinceName ? customerObj.receiveDistrict.provinceName : '' }}
              {{ customerObj.receiveDistrict.cityName ? customerObj.receiveDistrict.cityName : '' }}
              {{ customerObj.receiveDistrict.districtName ? customerObj.receiveDistrict.districtName : '' }}
              {{ customerObj.receiveAddr ? customerObj.receiveAddr : '' }}</span>
          </div>
        </div>
        <Loading :is-loading="isLoading" />
        <!-- 无数据 -->
        <EmptyData v-if="emptyData" />
        <ShopAndGood
          v-else
          ref="shopAndGood"
          :select-activity-id="selectActivityId"
          :valid-cart-list="validCartList"
          :invalid-cart-list="invalidCartList"
          :customer-obj="customerObj"
          :page-type="pageType"
          :checked-all-status="checkedAllStatus"
          @updateSelectShop="updateSelectShop"
          @updateExpandStatus="updateExpandStatus"
          @selectPromotion="selectPromotion"
          @updateCheckedAll="updateCheckedAll"
          @updateCombination="updateCombination"
          @checkAllCondition="checkAllCondition"
          @handleCarData="handleCarData"
          @updateDeleteProduct="updateDeleteProduct"
          @setAsyncQueryCart="setAsyncQueryCart"
        />
      </div>
    </div>
    <BottomBar
      :actual-price="carData.actualPrice"
      :total-price="carData.totalPrice"
      :activity-discount-amount="carData.activityDiscountAmount"
      :valid-cart-list="validCartList"
      :checked-all-status="checkedAllStatus"
      :customer-obj="customerObj"
      :selected-items-num-sub="selectedItemsNumSub"
      :selected-items-num-total-sub="selectedItemsNumTotalSub"
      :detail-data="carData"
      :empty-data="emptyData"
      @updateCheckedAll="updateCheckedAll"
      @batchVerify="batchVerify"
      @goodChange="goodChange"
      @checkGoodPackageMail="checkGoodPackageMail"
      @initChangeData="initChangeData"
      @delMulti="delMulti"
      @refreshPage="refreshPage"
      @queryCart="queryCart"
    />
    <GoodsChange
      :show-popup.sync="goodsChangeModal"
      :activity-limit="activityLimit"
      :valid-cart-list="validCartList"
      :origin-activity-limit="originActivityLimit"
      :change-goods-list="changeGoodsList"
      :check-good-type="checkGoodType"
      :change-package-mail-list="changePackageMailList"
      :origin-batch-verify-list="originBatchVerifyList"
      :batch-verify-list="batchVerifyList"
      :batch-limit-list="batchLimitList"
      :product-stock-check-list="productStockCheckList"
      :combination-stock-check-list="combinationStockCheckList"
      @checkGoodPackageMail="checkGoodPackageMail"
    />
    <MatchingShipping
      :show-popup.sync="shippingModal"
      :batch-verify-list="batchVerifyList"
      :valid-cart-list="validCartList"
      :change-package-mail-list="changePackageMailList"
      :activity-limit="activityLimit"
      :check-good-type="checkGoodType"
      @checkGoodPackageMail="checkGoodPackageMail"
    />
    <Modal v-model="tipShow" width="360" :closable="false" :mask-closable="false" class-name="auth-modal">
      <div class="tip-content">
        <div class="title">温馨提示</div>
        <div class="content">{{ tipTxt }}</div>
      </div>
      <template #footer>
        <div class="footer-section" :class="!showCancelButton ? 'single' : ''">
          <Button v-if="showCancelButton" class="left-btn" @click="cancel">{{ cancelText }}</Button>
          <Button class="right-btn" @click="confirm">{{ confirmText }}</Button>
        </div>
      </template>
    </Modal>
    <FrozenPopup :show-frozen-popup.sync="frozenShow" :tip="tip" />

    <ExchangePopup
      :visible.sync="showExchangePopup"
      :exchange-popup-obj="exchangePopupObj"
      @showModal="val=>showExchangeModal=val"
    />
    <ExchangeModal
      :visible.sync="showExchangeModal"
      :title="exchangePopupObj.lastMsgTitle"
    />
  </div>
</template>

<script>
import ShopAndGood from './component/shopAndGood.vue'
import BottomBar from './component/bottomBar.vue'
import GoodsChange from './component/goodsChange.vue'
import MatchingShipping from './component/matchingShipping.vue'
import EmptyData from './component/emptyData.vue'
import Loading from '@/components/Loading.vue'
import FrozenPopup from '@/components/FrozenPopup.vue'
import { getCartList, updateSkuChecked, updateSkuNum, getByCustomerId, changeGoodPromotion, checkCustomerNumberByUserId, electronExchangeagreeFalg } from '@/common/service'
import { ACTIVITY_TYPE, setStorageSync, mul, zhugeBrowsePageTrack } from '@/common/utils'
import { Message, Modal } from 'view-design'
import asyncQueryCartMixin from '@/common/mixins/asyncQueryCartMixin'
import ExchangePopup from '@/components/OpenAccountExchange/ExchangePopup.vue'
import ExchangeModal from '@/components/OpenAccountExchange/ExchangeModal.vue'
import { mapGetters } from 'vuex'

let skuCheckedCount = 0; let skuNumCount = 0
export default {
  name: 'CarPage',
  components: { ShopAndGood, BottomBar, GoodsChange, MatchingShipping, EmptyData, Loading, FrozenPopup, ExchangePopup, ExchangeModal },
  mixins: [asyncQueryCartMixin],
  data() {
    return {
      changeGoodsList: null, // 商品变动明细
      originBatchVerifyList: null, // 原始库存数据
      changePackageMailList: null, // 起配包邮明细
      originActivityLimit: null, // 换购品活动最大限购数量
      checkGoodType: '', // 结算校验的类型
      batchVerifyList: null, // 批量校验库存
      combineActivityList: null, // 组合购校验库存
      batchLimitList: null, // 批量校验限购
      activityLimit: null, // 换购品活动最大限购数量
      customerObj: {}, // 默认收货地址
      carData: {}, // 购物车数据
      isLoading: true,
      emptyData: false,
      validCartList: [], // 有效商品
      invalidCartList: [], // 失效商品
      pageType: 'tabBar',
      changePromotionObj: {},
      selectActivityId: '', // 当前选中的活动id
      checkedAllStatus: false, // 是否全选
      shippingModal: false,
      goodsChangeModal: false,
      isGetNum: false, // 监听锁
      selectedItemsNumSub: 0,
      selectedItemsNumTotalSub: 0,
      tipShow: false,
      tipTxt: '',
      confirmText: '',
      cancelText: '',
      showCancelButton: false,
      frozenShow: false,
      tip: '', // 冻结或资质到期提示文案
      offsetHeight: 0,
      // 是否显示开户电子资料交换弹窗
      showExchangePopup: false,
      showExchangeModal: false,
      exchangePopupObj: {},
      startTime: null, // 开始时间
      endTime: null, // 结束时间
      renderTime: 0, // 完成时间
      productStockCheckList: [], // 购物车商品库存校验列表
      combinationStockCheckList: [], // 购物车组合购商品库存校验列表
    }
  },
  computed: {
    ...mapGetters({
      customerInfo: 'common/getCustomerInfo'
    })
  },
  watch: {
    validCartList: {
      handler(newVal) {
        if (this.isGetNum) {
          this.getGoodsNum()
          this.isGetNum = !this.isGetNum
        } else {
          this.isGetNum = !this.isGetNum
        }
        this.$nextTick(() => {
          const el = document.querySelector('.car')
          this.offsetHeight = el.offsetHeight
        })
      }
    },
    offsetHeight(newVal, oldVal) {
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      const bottomBar = document.querySelector('.bottom-bar')
      const carContent = document.querySelector('.car-content')
      if (this.offsetHeight > windowHeight) {
        bottomBar.style.position = 'fixed'
        carContent.style.marginBottom = '84px'
      } else {
        bottomBar.style.position = 'static'
        carContent.style.marginBottom = '84px'
      }
    }
  },
  created() {
    // 记录页面渲染开始时间
    this.startTime = new Date().getTime();
    this.$emit('footer', false)
    this.checkCustomer()
    this.getCartListFn()
    this.$bus.$on('getCartListFn', () => {
      this.getCartListFn()
    })
    // 清空后更新商品
    this.$bus.$on('editCartInfo', () => {
      this.invalidCartList = []
      this.$forceUpdate()
      this.isEmptyData()
      this.checkAllCondition()
      this.$store.dispatch('car/updateCartNum')
    })
    // 选择单个有效商品 选中或取消选中
    this.$bus.$on('updateSelectGood', (data, sIndex, aIndex, pIndex, type) => {
      const activityType = this.validCartList[sIndex]?.activityList[aIndex]?.activityType
      const activityId = this.validCartList[sIndex]?.activityList[aIndex]?.activityId
      const flag = (activityType == ACTIVITY_TYPE.AUTOEXCHANGE || activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
        activityType == ACTIVITY_TYPE.GIFT_AUTO || activityType == ACTIVITY_TYPE.GIFT_MANUAL) && data?.expandMap?.present == true
      // console.log('选择商品',data,sIndex,aIndex,pIndex)
      const product = this.validCartList[sIndex]?.activityList[aIndex]?.productList || []
      this.$set(product, pIndex, { ...product[pIndex], checked: data.checked })
      this.selectActivityId = this.validCartList[sIndex]?.activityList[aIndex]?.activityId
      if (!this.isEdit) {
        if (type == 'changeNum') {
          this.updateSkuNum(data)
          // console.log('data',data)
        } else if (type == 'changePromotion') {
          this.updateChangePromotion(data, 'changePromotion')
        } else {
          // console.log('data',data)
          const cancelProductList = []
          if (!data.checked) {
            data = { ...data, activityId: this.validCartList[sIndex].activityList[aIndex].activityId, activityType: this.validCartList[sIndex].activityList[aIndex].activityType }
            if (!flag) {
              cancelProductList.push(data)
            }
          }
          this.updateSkuChecked(cancelProductList)
        }
      }
      let shopGoodNum = 0
      let selectedGoodNum = 0
      if (this.validCartList[sIndex]?.activityList?.length > 0) {
        for (const aItem of this.validCartList[sIndex]?.activityList) {
          shopGoodNum += aItem.productList.length
          for (const gItem of aItem?.productList) {
            if (gItem.checked) {
              selectedGoodNum++
            }
          }
        }
      }
      // console.log('选商品',selectedGoodNum,shopGoodNum)
      if (selectedGoodNum === shopGoodNum) {
        this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], checked: true })
      } else {
        this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], checked: false })
      }
      this.checkAllCondition()
    })
    // 换促销
    this.$bus.$on('changePromotion', (goodItem, activeItem, shopItem, shopIndex, activeIndex, goodIndex) => {
      this.selectActivityId = activeItem.activityId
      this.changePromotionObj = {
        goodItem,
        activeItem,
        shopItem,
        shopIndex,
        activeIndex,
        goodIndex
      }
    })
    setTimeout(() => {
      this.checkShowExchangePopup()
    }, 2000)
  },
  mounted() {
    document.body.addEventListener('click', this.closeCoupon)
  },
  destroyed() {
    // 设置购物车选中商品缓存数据
    this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
    this.$bus.$off('getCartListFn')
    this.$bus.$off('clearCarData')
    this.$bus.$off('editCartInfo')
    this.$bus.$off('updateSelectGood')
    this.$bus.$off('updateInvalidGood')
    this.$bus.$off('changePromotion')
    document.body.removeEventListener('click', this.closeCoupon)
  },
  methods: {
    closeCoupon() {
      // 关闭优惠券详情
      this.$bus.$emit('close-coupon')
    },
    // 删除换购品
    updateDeleteProduct(pIndex, aIndex, sIndex) {
      const activityItem = this.validCartList[sIndex]?.activityList[aIndex] || {}
      this.validCartList[sIndex]?.activityList[aIndex]?.productList?.splice(pIndex, 1)
      const flag = (activityItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || activityItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
        activityItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || activityItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL)
      if (activityItem.productList?.every(item => item?.expandMap?.present == true && flag)) {
        activityItem.productList = []
      }
      if (this.validCartList[sIndex]?.activityList[aIndex]?.productList?.length === 0) {
        this.validCartList[sIndex].activityList.splice(aIndex, 1)
      }
      if (this.validCartList[sIndex]?.activityList?.length === 0) {
        this.validCartList.splice(sIndex, 1)
      }
      this.checkAllCondition()
      this.isEmptyData()
    },
    delMulti() {
      this.$refs.shopAndGood.deleteGoodsBatch()
    },
    // 模态框暂不关联按钮
    cancel() {
      this.tipShow = false
      this.$router.push('/')
    },
    // 模态框确认按钮
    confirm() {
      this.tipShow = false
      if (this.tipType == 'noAuth') {
        this.$router.push('/')
      } else {
        this.$router.push('/ProductEntry')
      }
    },
    getGoodsNum() {
      let subNum1 = 0
      let subNum2 = 0
      for (const i in this.validCartList) {
        let num1 = 0
        let num2 = 0
        for (const j in this.validCartList[i].activityList) {
          for (const k in this.validCartList[i].activityList[j].productList) {
            if (this.validCartList[i].activityList[j].productList[k].checked) {
              num1 += 1
              num2 += this.validCartList[i].activityList[j].productList[k].num
            }
          }
        }
        this.$set(this.validCartList[i], 'selectedItemsNum', num1)
        this.$set(this.validCartList[i], 'selectedItemsNumTotal', num2)
        subNum1 += this.validCartList[i].selectedItemsNum
        subNum2 += this.validCartList[i].selectedItemsNumTotal
      }
      this.selectedItemsNumSub = subNum1
      this.selectedItemsNumTotalSub = subNum2
    },
    initChangeData() { // 初始化结算验证的数据
      this.changeGoodsList = null
      this.changePackageMailList = null
      this.batchVerifyList = null
      this.originBatchVerifyList = null
      this.batchLimitList = null
      this.originBatchVerifyList = null
      this.activityLimit = null
      this.originActivityLimit = null
    },
    // 点击去结算批量校验商品库存
    batchVerify(data) {
      this.batchVerifyList = data?.batchStock || {}
      this.originBatchVerifyList = JSON.parse(JSON.stringify(this.batchVerifyList))
      this.batchLimitList = data?.residuePurchaseCtrl || {}
      this.activityLimit = data?.activityLimit || {}
      this.originActivityLimit = JSON.parse(JSON.stringify(this.activityLimit))
      this.changeGoodsList = data?.changeProduct || {}
      if (Object.keys(this.changeGoodsList).length > 0) {
        Reflect.ownKeys(this.changeGoodsList).forEach(key => {
          if (key != 'stockOut' && key != 'soldOut' && key != 'noPurchase') {
            delete this.changeGoodsList[key]
          }
        })
        Reflect.ownKeys(this.changeGoodsList).forEach(key => {
          this.changeGoodsList[key] = { products: this.changeGoodsList[key], originProducts: this.changeGoodsList[key], expandStatus: false }
        })
        this.changeGoodsList = Object.assign({}, this.changeGoodsList)
      }
      this.combinationStockCheckList = data?.combineActivityValidList
      console.log(this.changeGoodsList)
      this.$forceUpdate()
      this.productStockCheckList = []
      this.goodsChangeModal = true
      this.checkGoodType = 'goodChange'
    },    
    // 点击去结算商品有变动、包含组合组合活动品
    goodChange(data, selectedProducts, settlePresentProductList) {
      this.changeGoodsList = data?.changeProduct || {}
      this.changePackageMailList = data?.postagePrompt || {}
      Reflect.ownKeys(this.changeGoodsList).forEach(key => {
        if (key === 'stockOut' || key === 'soldOut' || key === 'noPurchase' || key === 'startLimitChange') {
          for (let i = 0; i < this.changeGoodsList[key]?.length; i++) {
            selectedProducts = selectedProducts.filter(item => item.id != this.changeGoodsList[key][i].id)
            settlePresentProductList = settlePresentProductList.filter(item => !(item.productId == this.changeGoodsList[key][i].productId && item.activityId == this.changeGoodsList[key][i].activityId && item.expandMap?.present == this.changeGoodsList[key][i].expandMap?.present))
          }
        } else {
          console.log("xxx", this.changeGoodsList[key])
          for (let i = 0; i < this.changeGoodsList[key]?.length; i++) {
            selectedProducts = selectedProducts.map(item => {
              if (item.id == this.changeGoodsList[key][i].id) {
                return { ...item, ...this.changeGoodsList[key][i] }
              } else {
                return item
              }
            })
            settlePresentProductList = settlePresentProductList.map(item => {
              if (item.activityId === this.changeGoodsList[key][i].activityId && item.productId == this.changeGoodsList[key][i].productId)
              {
                return { ...item, ...{
                  num: this.changeGoodsList[key][i].num
                }}
              } else {
                return item
              }
            })
            // 更新购物车商品数据
            this.validCartList.forEach((item) => {
              if (item.activityList && item.activityList.length > 0) {
                item.activityList.forEach((aItem) => {
                  if (aItem.productList && aItem.productList.length > 0) {
                    aItem.productList.forEach((pItem) => {
                      if (pItem.activityId === this.changeGoodsList[key][i].activityId && pItem.productId === this.changeGoodsList[key][i].productId && pItem.expandMap?.present == this.changeGoodsList[key][i].expandMap?.present) {
                        pItem.num = this.changeGoodsList[key][i].num
                        console.log('pItem', pItem.activityId, pItem.productId, )
                      }
                    })
                  }
                })
              }
            })
          }

          console.log('this.validCartList', this.validCartList)
        }
        setStorageSync('settleProducts', selectedProducts)
        setStorageSync('settlePresentProductList', settlePresentProductList)
        // 设置购物车选中商品缓存数据
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
        console.log('结算实际入参',this.validCartList)
        this.changeGoodsList[key] = { products: this.changeGoodsList[key], originProducts: this.changeGoodsList[key], expandStatus: false }
        // 不做截取处理
        // if (this.changeGoodsList[key].length > 5) {
        //   this.changeGoodsList[key].products = this.changeGoodsList[key].products.slice(0, 5)
        // }
      })
      this.changeGoodsList = Object.assign({}, this.changeGoodsList)
      this.combinationStockCheckList = data?.combineActivityValidList || []
      console.log("商品变化数据", this.combinationStockCheckList)
      this.productStockCheckList = []
      this.goodsChangeModal = true
      this.checkGoodType = 'goodChange'
    },
    // 点击继续结算店铺不满足起配、包邮
    checkGoodPackageMail(data) {
      this.changePackageMailList = data
      this.shippingModal = true
      this.checkGoodType = 'goodPackageMail'
    },
    checkCustomer() {
      Promise.all([checkCustomerNumberByUserId(), getByCustomerId()]).then(res => {
        if (!res[0]?.data || res[0]?.data?.length === 0) {
          this.isLoading = false
          this.tipTxt = '当前账号未关联企业，请先关联'
          this.confirmText = '去关联'
          this.cancelText = '暂不关联'
          this.showCancelButton = true
          this.tipShow = true
          return
        }
        // 默认的收货地址
        this.customerObj = res[1]?.data?.receiveAddrList?.find(item => item.isCheck === 1) || {}
        if (res[1]?.data?.controlState === 'frozen') {
          this.isLoading = false
          this.tip = '您的账号已冻结，请联系客服处理'
          this.frozenShow = true
          return
        }
        if (res[1]?.data?.certState === 'expire') {
          this.isLoading = false
          this.tip = '您的资质已到期，请联系客服处理'
          this.frozenShow = true
          return
        }
        if (res[1]?.data?.userState !== 'auth') {
          this.isLoading = false
          this.tipTxt = '资质审核中，请耐心等待'
          this.confirmText = '知道了'
          this.showCancelButton = false
          this.tipType = 'noAuth'
          this.tipShow = true
          return
        }
        // this.getCartListFn()
      }).catch(error => {
        this.isLoading = false
        Message.error(error?.data?.message)
      })
    },
    // 处理购物车数据、queryCart接口
    handleCarData(data) {
      this.carData = data
      this.validCartList = JSON.parse(JSON.stringify(this.carData.storeList || []))
      this.invalidCartList = JSON.parse(JSON.stringify(this.carData.invalidProductList || []))
      this.isEmptyData()
      this.invalidCartList = this.invalidCartList.map(item => { return { ...item, 'status': 'lose' } })
      if (this.selectedProductList?.length > 0 && this.validCartList.length > 0 && this.checkedIsHasCheckedGoods(this.selectedProductList)) {
        // console.log('前端缓存有选中数据')
        this.updateSelectedData(this.selectedProductList)// 切换页面后回显选中状态
        this.checkAllCondition()
      } else {
        // console.log('前端缓存没有选中数据')
        if (this.validCartList?.length > 0) {
          for (const sItem of this.validCartList) {
            this.$set(sItem, 'expandStatus', true)
            this.$set(sItem, 'showCoupon', false)
            this.$set(sItem, 'checked', false)
            for (const aItem of sItem?.activityList) {
              if (aItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || aItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION) {
                this.$set(aItem, 'numLoading', false)
              }
              for (const gItem of aItem?.productList) {
                this.$set(gItem, 'checked', false)
                this.$set(gItem, 'promotionModalShow', false)
                this.$set(gItem, 'numLoading', false)
              }
            }
          }
        }
        this.checkAllCondition()
      }
      this.computedPurchasedNum()
      // const cachedProductList = JSON.parse(localStorage.getItem('cachedProductList') || '[]')
      // if (cachedProductList && cachedProductList.length > 0) {
      //   this.initCheckedData(true)
      // }
    },
    getCartListFn() {
      const selectedProductList = []; const presentProductList = []; const cancelProductList = []
      if (this.selectedProductList?.length > 0) {
        for (const sItem of this.selectedProductList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType === ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present === true
              if (gItem?.selected) {
                if (!flag) {
                  selectedProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    num: gItem.num,
                    price: gItem.price,
                    actualPrice: gItem.actualPrice
                  })
                }
              } else {
                if (!flag) {
                  cancelProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    num: gItem.num,
                    price: gItem.price,
                    actualPrice: gItem.actualPrice
                  })
                }
              }
              // 31自动换购 32手动换购
              if (flag) {
                presentProductList.push({
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  selected: gItem.selected,
                  num: gItem.num,
                  actualPrice: gItem.actualPrice,
                  price: gItem.price
                })
              }
            }
          }
        }
      }
      this.isLoading = true
      getCartList({
        presentProductList,
        cancelProductList,
        selectedProductList
      }).then(res => {
        this.isLoading = false
        // 活动商品库存校验
        const { productStockCheckList } = res.data;
        if (productStockCheckList && productStockCheckList.length > 0) {
          this.onProductStockChange(res.data)
        }
        this.handleCarData(res.data)
        if (this.startTime) {
          this.eventTrack()
        }
      }).catch(err => {
        this.isLoading = false
        // 如果当前登录用户必要信息缺失600 传入的customer不是当前用户的关联客户 提示602
        if (err?.data?.code === '600') {
          Message.error('当前账号未关联企业，请先关联')
          return
        }
        console.log('err', err)
        Message.error(err?.data?.message || '查询购物车异常')
      })
    },
    computedPurchasedNum() {
      for (const sItem of this.validCartList) {
        for (const aItem of sItem?.activityList) {
          for (const gItem of aItem?.productList) {
            const isCombinationGood = !!(aItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || aItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION) // 是否是组合活动
            if (isCombinationGood) {
              this.$set(gItem, 'num', mul(aItem.expandMap.units, gItem.startBuyNum))
            }
          }
        }
      }
    },
    // 全选条件 店铺全选  反之
    checkAllCondition() {
      let isCheckAllNum = 0
      for (let i = 0; i < this.validCartList.length; i++) {
        if (this.validCartList[i]?.checked) {
          isCheckAllNum++
        }
      }
      // console.log('选店铺',isCheckAllNum,this.validCartList.length)
      let isCheckLoseNum = 0
      for (let m = 0; m < this.invalidCartList.length; m++) {
        if (this.invalidCartList[m].checked) {
          isCheckLoseNum++
        }
      }
      if (!this.isEdit) {
        this.checkedAllStatus = !!(this.validCartList.length !== 0 && isCheckAllNum === this.validCartList.length)
      } else {
        this.checkedAllStatus = !!(isCheckAllNum === this.validCartList.length && isCheckLoseNum === this.invalidCartList.length && (this.validCartList.length !== 0 || this.invalidCartList.length !== 0))
      }
    },
    // 商品选中或取消选中操作
    updateSkuChecked(cancelProductList) {
      const selectedProductList = []; const presentProductList = []
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present == true
              if (gItem.checked) {
                if (!flag) {
                  selectedProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    num: gItem.num,
                    price: gItem.price,
                    actualPrice: gItem.actualPrice
                  })
                }
              }
              // 31自动换购 32手动换购
              if (flag) {
                presentProductList.push({
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  selected: gItem.checked,
                  num: gItem.num,
                  actualPrice: gItem.actualPrice,
                  price: gItem.price
                })
              }
            }
          }
        }
      }
      if (cancelProductList && cancelProductList.length > 0) {
        cancelProductList = cancelProductList.map(item => {
          return {
            id: item.id,
            productId: item.productId,
            activityId: item.activityId,
            activityType: item.activityType,
            orgId: item.orgId,
            num: item.num,
            price: item.price,
            actualPrice: item.actualPrice
          }
        })
      }
      this.isLoading = true
      updateSkuChecked({
        addressCode: this.customerObj.receiveDistrictNo,
        customerTypeId: this.customerObj?.customerTypeId,
        presentProductList,
        cancelProductList,
        selectedProductList
      }).then(res => {
        this.isLoading = false
        if (res.data.status == '1') {
          if (skuCheckedCount >= 1) {
            this.updateCheckedData(res.data)
            return
          }
          // 设置购物车选中商品缓存数据
          this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
          Modal.info({
            content: '该商品参与的活动已变化',
            okText: '我知道了',
            onOk: () => {
              skuCheckedCount++
              this.getCartListFn()
            }
          })
        } else {
          // 活动商品库存校验
          const { productStockCheckList } = res.data;
          if (productStockCheckList && productStockCheckList.length > 0) {
            this.onProductStockChange(res.data)
          }
          this.updateCheckedData(res.data)
        }
      }).catch(err => {
        this.isLoading = false
        // 多页签，操作购物车时不同情况的返回状态
        if (err?.data?.code === 566 || err?.data?.code === 569) {
          Modal.info({
            content: `${err?.data?.message || '商品信息有变化'}`,
            okText: '我知道了',
            title: '温馨提示',
            onOk: () => {
              this.queryCart()
            }
          })
        } else {
          Message.error(err?.data?.message)
        }
      })
    },
    // 更新反显回来的数据选中状态
    updateSelectedData(data) {
      const selectGoodsArr = []
      if (data?.length > 0) {
        for (const [sIndex, sItem] of data?.entries()) {
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            for (const [gIndex, gItem] of aItem?.productList?.entries()) {
              // 替换的数据在购物车数据中
              selectGoodsArr.push({
                id: gItem.id,
                productId: gItem.productId,
                activityId: aItem.activityId,
                activityType: aItem.activityType,
                expandMap: gItem.expandMap,
                sIndex,
                aIndex,
                gIndex
              })
            }
          }
        }
      }
      // console.log('selectGoodsArr',selectGoodsArr)
      const pIds = selectGoodsArr.map(item => { return { id: item.id, productId: item.productId, activityId: item.activityId, expandMap: item.expandMap } })
      if (this.validCartList?.length > 0) {
        for (const [sIndex, sItem] of this.validCartList?.entries()) {
          const mergeCoupons = sItem.mergeCoupons || []
          if (mergeCoupons.length > 0) {
            const mergeCouponsCurrency = sItem?.mergeCoupons.filter((cItem) => cItem.couponType !== 'coupon_commodity')
            const mergeCouponsGoods = sItem?.mergeCoupons.filter((cItem) => cItem.couponType === 'coupon_commodity')
            this.$set(this.validCartList[sIndex], 'mergeCouponsCurrency', mergeCouponsCurrency)
            this.$set(this.validCartList[sIndex], 'mergeCouponsGoods', mergeCouponsGoods)
          }
          this.$set(sItem, 'expandStatus', true)
          let shopGoodNum = 0 // 店铺下商品总数
          let selectedGoodNum = 0 // 店铺下已选中商品总数
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            let selectedSingGoodNum = 0 // 单个活动下选中的商品数
            shopGoodNum += aItem.productList.length
            for (const [gIndex, gItem] of aItem?.productList?.entries()) {
              let dIndex = null
              if (gItem?.expandMap?.present) {
                dIndex = pIds.findIndex(item => item.activityId == aItem.activityId && item.productId == gItem.productId && item?.expandMap?.present == gItem?.expandMap?.present)
              } else {
                dIndex = pIds.findIndex(item => item.activityId == aItem.activityId && item.productId == gItem.productId)
              }
              // 判断接口返回的selected是否为true，给checked赋值
              this.$set(gItem, 'checked', gItem.selected)
              if (gItem.selected === true) {
                selectedGoodNum++
                selectedSingGoodNum++
              }
              if (dIndex != -1) {
                // const selectGood = selectGoodsArr[dIndex]
                // const goodItem = data[selectGood.sIndex].activityList[selectGood.aIndex].productList[selectGood.gIndex]
                // this.$set(gItem, 'checked', goodItem.selected)
                // if (gItem.checked) {
                //   selectedGoodNum++
                //   selectedSingGoodNum++
                // }
              } else {
                if ((aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem?.expandMap?.present == true) {
                  // 自动换购替补的换购品默认选中状态
                  this.$set(gItem, 'checked', true)
                  if (gItem.checked) {
                    selectedGoodNum++
                    selectedSingGoodNum++
                  }
                }
              }
            }
            if (selectedSingGoodNum === aItem.productList.length) {
              this.$set(this.validCartList[sIndex].activityList, aIndex, { ...this.validCartList[sIndex].activityList[aIndex], checked: true })
            } else {
              this.$set(this.validCartList[sIndex].activityList, aIndex, { ...this.validCartList[sIndex].activityList[aIndex], checked: false })
            }
          }
          // console.log('选商品',selectedGoodNum,shopGoodNum)
          if (selectedGoodNum === shopGoodNum) {
            this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], checked: true })
          } else {
            this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], checked: false })
          }
        }
      }
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            if (aItem.activityType == ACTIVITY_TYPE.FIXEDCOMBINATION || aItem.activityType == ACTIVITY_TYPE.CUSTOMCOMBINATION) {
              this.$set(aItem, 'numLoading', false)
            }
            for (const gItem of aItem?.productList) {
              this.$set(gItem, 'numLoading', false)
            }
          }
        }
      }
      this.$set(this.carData, 'storeList', this.validCartList)
    },
    // 判断本地缓存是否有选中的商品
    checkedIsHasCheckedGoods(data) {
      let isHasChecked = false
      if (data?.length > 0) {
        for (const sItem of data) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              if (gItem.selected) {
                isHasChecked = true
              }
            }
          }
        }
      }
      if (!isHasChecked) {
        return false
      } else {
        return true
      }
    },
    isEmptyData() {
      this.emptyData = !!(this.validCartList.length === 0 && this.invalidCartList.length === 0 && !this.isLoading)
      // console.log('emptyData',this.emptyData)
    },
    // 店铺展开收起
    updateExpandStatus(data, index) {
      this.$set(this.validCartList, index, { ...this.validCartList[index], 'expandStatus': data })
    },
    // 选择店铺
    updateSelectShop(data, index) {
      let cancelProductList = []
      this.$set(this.validCartList, index, { ...this.validCartList[index], checked: data })
      if (this.validCartList[index]?.activityList && this.validCartList[index]?.activityList.length > 0) {
        for (const aItem of this.validCartList[index]?.activityList) {
          this.$set(aItem, 'checked', data)
          if (!data) {
            aItem.productList.map(item => { return { ...item, activityId: aItem.activityId, activityType: aItem.activityType } })
            const cancelProducts = aItem.productList.filter(item => item.expandMap.present != true) || []
            cancelProductList = [...cancelProductList, ...cancelProducts]
          }
          for (const gItem of aItem.productList) {
            if (!(gItem.isLimit == 1 && gItem.residualQty <= 0)) {
              this.$set(gItem, 'checked', data)
            }
          }
        }
      }
      this.checkAllCondition()
      if (!this.isEdit) {
        this.updateSkuChecked(cancelProductList)
      }
    },
    // 组合活动商品以活动纬度勾选、取消勾选、删除
    updateCombination(data, sIndex, aIndex, activityNum, type) {
      let cancelProductList = []
      activityNum && this.$set(this.validCartList[sIndex].activityList[aIndex].expandMap, 'units', activityNum)
      for (const gItem of this.validCartList[sIndex].activityList[aIndex].productList) {
        activityNum && this.$set(gItem, 'num', mul(activityNum, gItem.startBuyNum))
        this.$set(gItem, 'checked', data.checked)
      }
      this.$set(this.validCartList[sIndex].activityList[aIndex], 'checked', data.checked)
      const aItemObj = this.validCartList[sIndex].activityList[aIndex]
      if (!data.checked) {
        aItemObj.productList.map(item => { return { ...item, 'activityId': aItemObj.activityId, 'activityType': aItemObj.activityType } })
        const cancelProducts = aItemObj.productList.filter(item => item.expandMap.present != true) || []
        cancelProductList = [...cancelProductList, ...cancelProducts]
      }
      if (!this.isEdit) {
        if (type == 'changeNum') {
          this.updateSkuNum(aItemObj)
        } else {
          this.updateSkuChecked(cancelProductList)
        }
      }
      let shopGoodNum = 0
      let selectedGoodNum = 0
      if (this.validCartList[sIndex]?.activityList?.length > 0) {
        for (const aItem of this.validCartList[sIndex]?.activityList) {
          shopGoodNum += aItem.productList.length
          for (const gItem of aItem?.productList) {
            if (gItem.checked) {
              selectedGoodNum++
            }
          }
        }
      }
      // console.log('选组合活动',selectedGoodNum,shopGoodNum)
      if (selectedGoodNum === shopGoodNum) {
        this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], 'checked': true })
      } else {
        this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], 'checked': false })
      }
      this.checkAllCondition()
    },
    // 换促销
    selectPromotion(item) { // 换促销
      const activeObj = JSON.parse(JSON.stringify(item))
      // eslint-disable-next-line prefer-const
      let { goodItem, activeItem, shopItem, shopIndex, activeIndex, goodIndex } = this.changePromotionObj
      const activeIds = this.validCartList[shopIndex].activityList.map(item => item.activityId)
      if (activeIds.includes(activeObj.activityId)) { // 购物车有要换的活动块
        if (goodItem.checked) { // 购物车换促销商品选中
          // 原活动块删除商品
          this.validCartList[shopIndex].activityList[activeIndex].productList.splice(goodIndex, 1)
          const exchangeItem = this.validCartList[shopIndex].activityList[activeIndex]
          if (exchangeItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) { // 当前商品为参与换购的商品
            // 换促销 当前参与换购的商品移除被换购商品
            if (exchangeItem.reachPrice) {
              exchangeItem.productList = exchangeItem.productList.filter(item => item?.expandMap?.present != true)
            }
          }
          // 原活动块下没有商品删除原活动块
          if (this.validCartList[shopIndex].activityList[activeIndex].productList.length == 0) {
            this.validCartList[shopIndex].activityList.splice(activeIndex, 1)
          } else {
            // 原活动块下剩余商品都未选中显示activityAllDesc标签
            const flag = this.validCartList[shopIndex].activityList[activeIndex].productList.every(item => item.checked != true)
            if (flag) {
              this.validCartList[shopIndex].activityList[activeIndex].activityDesc = this.validCartList[shopIndex].activityList[activeIndex].activityAllDesc
            }
          }
          // 换促销活动块追加商品信息
          goodItem = { ...goodItem, ...activeObj }
          goodItem.activityPrice = goodItem.price
          for (const aItem of this.validCartList[shopIndex]?.activityList) {
            if (activeObj.activityId == aItem.activityId) {
              aItem.productList.unshift(goodItem)
            }
          }
          // console.log('validCartList', this.validCartList)
          // 调换促销接口
          if (this.validCartList?.length > 0) {
            for (const [sIndex, sItem] of this.validCartList?.entries()) {
              for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
                for (const [gIndex, gItem] of aItem?.productList?.entries()) {
                  if (gItem.id == goodItem.id) {
                    this.$bus.$emit('updateSelectGood', goodItem, sIndex, aIndex, gIndex, 'changePromotion')
                  }
                }
              }
            }
          }
        } else { // 购物车换促销商品未选中
          // 原活动块删除商品
          this.validCartList[shopIndex].activityList[activeIndex].productList.splice(goodIndex, 1)
          const exchangeItem = this.validCartList[shopIndex].activityList[activeIndex]
          if (exchangeItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) { // 当前商品为参与换购的商品
            // 换促销 当前参与换购的商品移除被换购商品
            if (exchangeItem.reachPrice) {
              exchangeItem.productList = exchangeItem.productList.filter(item => item?.expandMap?.present != true)
            }
          }
          // 原活动块下没有商品删除原活动块
          if (this.validCartList[shopIndex].activityList[activeIndex].productList.length == 0) {
            this.validCartList[shopIndex].activityList.splice(activeIndex, 1)
          } else {
            // 原活动块下剩余商品都未选中显示activityAllDesc标签
            const flag = this.validCartList[shopIndex].activityList[activeIndex].productList.every(item => item.checked != true)
            if (flag) {
              this.validCartList[shopIndex].activityList[activeIndex].activityDesc = this.validCartList[shopIndex].activityList[activeIndex].activityAllDesc
            }
          }
          // 换促销活动块追加商品信息
          goodItem = { ...goodItem, ...activeObj }
          goodItem.activityPrice = goodItem.price
          for (const aItem of this.validCartList[shopIndex]?.activityList) {
            if (activeObj.activityId == aItem.activityId) {
              aItem.productList.unshift(goodItem)
            }
          }
          // console.log('validCartList', this.validCartList)
          // 选中商品
          this.$set(goodItem, 'checked', true)
          // 调换促销接口
          if (this.validCartList?.length > 0) {
            for (const [sIndex, sItem] of this.validCartList?.entries()) {
              for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
                for (const [gIndex, gItem] of aItem?.productList?.entries()) {
                  if (gItem.id == goodItem.id) {
                    this.$bus.$emit('updateSelectGood', goodItem, sIndex, aIndex, gIndex, 'changePromotion')
                  }
                }
              }
            }
          }
        }
      } else { // 购物车没有要换的活动块
        if (goodItem.checked) { // 购物车换促销商品选中
          // 原活动块删除商品
          this.validCartList[shopIndex].activityList[activeIndex].productList.splice(goodIndex, 1)
          const exchangeItem = this.validCartList[shopIndex].activityList[activeIndex]
          if (exchangeItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) { // 当前商品为参与换购的商品
            // 换促销 当前参与换购的商品移除被换购商品
            if (exchangeItem.reachPrice) {
              exchangeItem.productList = exchangeItem.productList.filter(item => item?.expandMap?.present != true)
            }
          }
          // 原活动块下没有商品删除原活动块
          if (this.validCartList[shopIndex].activityList[activeIndex].productList.length == 0) {
            this.validCartList[shopIndex].activityList.splice(activeIndex, 1)
          } else {
            // 原活动块下剩余商品都未选中显示activityAllDesc标签
            const flag = this.validCartList[shopIndex].activityList[activeIndex].productList.every(item => item.checked != true)
            if (flag) {
              this.validCartList[shopIndex].activityList[activeIndex].activityDesc = this.validCartList[shopIndex].activityList[activeIndex].activityAllDesc
            }
          }
          // 新增活动块
          this.validCartList[shopIndex].activityList.splice(activeIndex, 0, activeObj)
          // 新增活动块追加商品信息
          goodItem = { ...goodItem, ...activeObj }
          goodItem.activityPrice = goodItem.price
          this.validCartList[shopIndex].activityList[activeIndex].productList = []
          this.validCartList[shopIndex].activityList[activeIndex].productList.unshift(goodItem)
          // console.log('validCartList', this.validCartList)
          // 调换促销接口
          if (this.validCartList?.length > 0) {
            for (const [sIndex, sItem] of this.validCartList?.entries()) {
              for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
                for (const [gIndex, gItem] of aItem?.productList?.entries()) {
                  if (gItem.id == goodItem.id) {
                    this.$bus.$emit('updateSelectGood', goodItem, sIndex, aIndex, gIndex, 'changePromotion')
                  }
                }
              }
            }
          }
        } else { // 购物车换促销商品未选中
          // 原活动块删除商品
          this.validCartList[shopIndex].activityList[activeIndex].productList.splice(goodIndex, 1)
          const exchangeItem = this.validCartList[shopIndex].activityList[activeIndex]
          if (exchangeItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) { // 当前商品为参与换购的商品
            // 换促销 当前参与换购的商品移除被换购商品
            if (exchangeItem.reachPrice) {
              exchangeItem.productList = exchangeItem.productList.filter(item => item?.expandMap?.present != true)
            }
          }
          // 原活动块下没有商品删除原活动块
          if (this.validCartList[shopIndex].activityList[activeIndex].productList.length == 0) {
            this.validCartList[shopIndex].activityList.splice(activeIndex, 1)
          } else {
            // 原活动块下剩余商品都未选中显示activityAllDesc标签
            const flag = this.validCartList[shopIndex].activityList[activeIndex].productList.every(item => item.checked != true)
            if (flag) {
              this.validCartList[shopIndex].activityList[activeIndex].activityDesc = this.validCartList[shopIndex].activityList[activeIndex].activityAllDesc
            }
          }
          // 新增活动块
          this.validCartList[shopIndex].activityList.splice(activeIndex, 0, activeObj)
          // 新增活动块追加商品信息
          goodItem = { ...goodItem, ...activeObj }
          goodItem.activityPrice = goodItem.price
          this.validCartList[shopIndex].activityList[activeIndex].productList = []
          this.validCartList[shopIndex].activityList[activeIndex].productList.unshift(goodItem)
          // console.log('validCartList', this.validCartList)
          // 选中商品
          this.$set(goodItem, 'checked', true)
          // 调换促销接口
          if (this.validCartList?.length > 0) {
            for (const [sIndex, sItem] of this.validCartList?.entries()) {
              for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
                for (const [gIndex, gItem] of aItem?.productList?.entries()) {
                  if (gItem.id == goodItem.id) {
                    this.$bus.$emit('updateSelectGood', goodItem, sIndex, aIndex, gIndex, 'changePromotion')
                  }
                }
              }
            }
          }
        }
      }
      // 锚点定位
      this.$bus.$emit('position', goodItem)
    },
    // 换促销接口
    updateChangePromotion(item, type) {
      const selectedProductList = []; const presentProductList = []
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap.present == true
              if (gItem.checked) {
                if (!flag) {
                  selectedProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    price: gItem.price,
                    actualPrice: gItem.actualPrice
                  })
                }
              }
              // 31自动换购 32手动换购
              if (flag) {
                presentProductList.push({
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  selected: gItem.checked,
                  num: gItem.num,
                  actualPrice: gItem.actualPrice,
                  price: gItem.price
                })
              }
            }
          }
        }
      }
      const product = { id: item.id, num: item.num }
      this.isLoading = true
      changeGoodPromotion({
        addressCode: this.customerObj.receiveDistrictNo,
        product,
        selectedProductList,
        presentProductList,
        customerTypeId: this.customerObj?.customerTypeId
      }).then(res => {
        this.isLoading = false
        // 活动商品库存校验
        const { productStockCheckList } = res.data;
        if (productStockCheckList && productStockCheckList.length > 0) {
          this.onProductStockChange(res.data)
        }
        this.updateCheckedData(res.data, type)
      }).catch(err => {
        this.isLoading = false
        // 多页签，操作购物车时不同情况的返回状态
        if (err?.data?.code === 566 || err?.data?.code === 569) {
          Modal.info({
            content: `${err?.data?.message || '商品信息有变化'}`,
            okText: '我知道了',
            title: '温馨提示',
            onOk: () => {
              this.queryCart()
            }
          })
        } else {
          Message.error(err?.data?.message)
        }
      })
    },
    // 对象合并
    assignObj(target = {}, sources = {}, type) {
      const obj = target
      if (typeof target !== 'object' || typeof sources !== 'object') {
        return sources
      }
      if (sources) {
        for (const key in sources) {
          if (type == 'changePromotion') {
            if (key == 'activityId' || key == 'activityType' || key == 'actualPrice') {
              if (target.hasOwnProperty(key)) {
                obj[key] = this.assignObj(target[key], sources[key])
              } else {
                obj[key] = sources[key]
              }
            }
          } else {
            if (key != 'productLogo' && target) {
              if (target.hasOwnProperty(key)) {
                obj[key] = this.assignObj(target[key], sources[key])
              } else {
                obj[key] = sources[key]
              }
            }
          }
        }
      }
      return obj
    },
    // 更新选中商品数据
    updateCheckedData(data, type) {
      const selectGoodsArr = []
      if (data && Object.keys(data).length > 0 && data?.storeList?.length > 0) {
        this.$set(this.carData, 'actualPrice', data?.actualPrice)
        this.$set(this.carData, 'totalPrice', data?.totalPrice)
        this.$set(this.carData, 'activityDiscountAmount', data?.activityDiscountAmount)
        this.$set(this.carData, 'selectedCount', data?.selectedCount)
        this.$set(this.carData, 'totalNum', data?.totalNum)
        this.$set(this.carData, 'unReceiveFlag', data?.unReceiveFlag)
        for (const [sIndex, sItem] of data?.storeList?.entries()) {
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            for (const [gIndex, gItem] of aItem?.productList?.entries()) {
              const composeItem = {
                ...gItem,
                ...{
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  productId: gItem.productId,
                  sIndex,
                  aIndex,
                  gIndex
                }
              }
              selectGoodsArr.push(composeItem)
            }
          }
        }
      }
      console.log("selectGoodsArr", selectGoodsArr, this.validCartList)
      if (this.validCartList?.length > 0) {
        for (const [sIndex, sItem] of this.validCartList?.entries()) {
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            for (const [gIndex, gItem] of aItem?.productList?.entries()) {
              // 替换的数据在购物车数据中
              const dIndex = selectGoodsArr.findIndex(item => item.activityId == aItem.activityId && item.productId == gItem.productId && item?.expandMap?.present == gItem?.expandMap?.present)
              const selectGood = selectGoodsArr[dIndex] || {}
              console.log("yyy", selectGood)
              if (selectGood && Object.keys(selectGood).length > 0) {
                const exchangeItem = data.storeList[selectGood.sIndex].activityList[selectGood.aIndex]
                // 31满换自动加购 32满换手动加购 71自动满赠  72手动满赠

                if (exchangeItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || exchangeItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) { // 当前商品为参与换购的商品
                  let beExchangedList = []// 被换购商品集合
                  if (exchangeItem.reachPrice) {
                    // 满换达到活动金额 当前参与换购的商品添加被换购商品
                    if (!(type == 'changePromotion' && exchangeItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE)) { // 换促销手动换购不需要更新商品信息
                      beExchangedList = selectGoodsArr.filter(item => item.activityId == exchangeItem.activityId && item?.expandMap?.present == true)
                      beExchangedList = beExchangedList.map(item => {
                        return { ...data.storeList[item.sIndex].activityList[item.aIndex].productList[item.gIndex], checked: item.selected, numLoading: false }
                      })
                      aItem.productList = aItem.productList.filter(item => aItem.activityId == exchangeItem.activityId && item?.expandMap?.present != true)
                      aItem.productList = [...aItem.productList, ...beExchangedList]
                    }
                  } else {
                    // 满换未达到活动金额 当前参与换购的商品移除被换购商品
                    aItem.productList = aItem.productList.filter(item => item.activityId == exchangeItem.activityId && item?.expandMap?.present != true)
                  }
                }
                const goodItem = exchangeItem.productList[selectGood.gIndex]
                this.assignObj(gItem, goodItem, type)
                // 设置商家凑券列表
                this.$set(this.validCartList[sIndex], 'mergeCoupons', sItem.mergeCoupons)
                const mergeCoupons = sItem.mergeCoupons || []
                if (mergeCoupons.length > 0) {
                  const mergeCouponsCurrency = sItem?.mergeCoupons.filter((cItem) => cItem.couponType !== 'coupon_commodity')
                  const mergeCouponsGoods = sItem?.mergeCoupons.filter((cItem) => cItem.couponType === 'coupon_commodity')
                  this.$set(this.validCartList[sIndex], 'mergeCouponsCurrency', mergeCouponsCurrency)
                  this.$set(this.validCartList[sIndex], 'mergeCouponsGoods', mergeCouponsGoods)
                }
                // 设置优惠券，优惠券列表
                if (this.validCartList[sIndex].activityList[aIndex].productList[gIndex]) {
                  this.$set(this.validCartList[sIndex].activityList[aIndex].productList[gIndex], 'couponMsg', goodItem.couponMsg)
                  this.$set(this.validCartList[sIndex].activityList[aIndex].productList[gIndex], 'couponPriceList', goodItem.couponPriceList)
                  // 设置商品selected状态
                  this.$set(this.validCartList[sIndex].activityList[aIndex].productList[gIndex], 'selected', goodItem.selected)
                  this.$set(this.validCartList[sIndex].activityList[aIndex].productList[gIndex], 'activityPrice', goodItem.activityPrice)
                }
                const activeItem = exchangeItem
                const activityObj = {
                  activityDesc: activeItem.activityDesc,
                  activityId: activeItem.activityId,
                  activityAllDesc: activeItem.activityAllDesc,
                  activityType: activeItem.activityType,
                  reachPrice: activeItem.reachPrice,
                  expandMap: activeItem.expandMap, // 是否是被换购品 组合活动最大限购套数 组合活动购买套数，判断是否为组合购，组合购不替换expandMap
                  activityAmount: activeItem.activityAmount,
                  activityDiscountAmount: activeItem.activityDiscountAmount,
                  activityTotalAmount: activeItem.activityTotalAmount, // 活动优惠金额
                  stairStep: activeItem.stairStep, // 是否为阶梯满赠
                }
                // 判断是否为组合购，操作行为是换促销
                if ([ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(activeItem.activityType) && ['changePromotion'].includes(type)) {
                  activityObj.expandMap = this.validCartList[sIndex].activityList[aIndex]?.expandMap
                }
                this.$set(this.validCartList[sIndex].activityList, aIndex, { ...this.validCartList[sIndex].activityList[aIndex], ...activityObj })
                const item = data.storeList[selectGood.sIndex]
                const storeObj = {
                  actualPrice: item.actualPrice,
                  storeId: item.storeId,
                  storeName: item.storeName,
                  orgPostage: item.orgPostage,
                  deliveryMerchant: item.deliveryMerchant,
                  specAmount: item.specAmount,
                  totalPrice: item.totalPrice,
                  totalNum: item.totalNum,
                  selectedCount: item.selectedCount,
                  discountAmount: item.discountAmount,
                  merchantActivityAmount: item.merchantActivityAmount,
                  universalCouponAmount: item.universalCouponAmount,
                  productCouponAmount: item.productCouponAmount,
                  productCouponList: item.productCouponList,
                  universalCouponList: item.universalCouponList,
                  couponList: item.couponList,
                  merchantCouponAmounts: item.merchantCouponAmounts,
                  mergeCoupons: item?.mergeCoupons,
                  mergeCouponsCurrency: item?.mergeCoupons ? item?.mergeCoupons.filter((cItem) => cItem.couponType !== 'coupon_commodity') : [],
                  mergeCouponsGoods: item?.mergeCoupons ? item?.mergeCoupons.filter((cItem) => cItem.couponType === 'coupon_commodity') : []
                }
                this.$set(this.validCartList, sIndex, { ...this.validCartList[sIndex], ...storeObj })
              }
            }
          }
        }
      }
      this.calculationQuantity(this.validCartList)
      console.log('替换后数据',this.validCartList)
      this.$set(this.carData, 'storeList', this.validCartList)
      this.$set(this.carData, 'loseProductList', this.invalidCartList)// invalidProductList
      // console.log(this.carData.storeList)
      // console.log('this.validCartList勾选',this.validCartList)
      // 设置购物车选中商品缓存数据
      this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      if (this.validCartList) {
        this.setAsyncQueryCart(this.validCartList)
      }
    },
    // 获取购物车字段数据
    getCartFieldData(list) {
      const selectedProductList = []
      const presentProductList = []
      const cancelProductList = []
      for (const sItem of list) {
        for (const aItem of sItem?.activityList) {
          for (const gItem of aItem?.productList) {
            const flag = (aItem.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType === ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present === true
            if (gItem.selected) {
              if (!flag) {
                selectedProductList.push({
                  id: gItem.id,
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  num: gItem.num,
                  price: gItem.price,
                  actualPrice: gItem.actualPrice
                })
              }
            } else {
              if (!flag) {
                cancelProductList.push({
                  id: gItem.id,
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  num: gItem.num,
                  price: gItem.price,
                  actualPrice: gItem.actualPrice
                })
              }
            }
            // 31自动换购 32手动换购
            if (flag) {
              presentProductList.push({
                productId: gItem.productId,
                activityId: aItem.activityId,
                activityType: aItem.activityType,
                orgId: gItem.orgId,
                selected: gItem.checked,
                num: gItem.num,
                actualPrice: gItem.actualPrice,
                price: gItem.price
              })
            }
          }
        }
      }
      return {
        selectedProductList,
        presentProductList,
        cancelProductList
      }
    },
    calculationQuantity(validCartList) {

    },
    updateLoadingStatus(item, flag) {
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            if (aItem.activityId == item.activityId) {
              this.$set(aItem, 'numLoading', flag)
            } else {
              this.$set(aItem, 'numLoading', aItem.numLoading || false)
            }
            for (const gItem of aItem?.productList) {
              if (gItem.productId == item.productId && aItem.activityId == item.activityId && gItem.expandMap?.present == item.expandMap?.present) {
                this.$set(gItem, 'numLoading', flag)
              } else {
                this.$set(gItem, 'numLoading', gItem.numLoading || false)
              }
            }
          }
        }
      }
    },
    // 更新商品数量
    updateSkuNum(item) {
      const selectedProductList = []; const presentProductList = []
      const isCombination = !!(item?.productList && item?.productList.length > 0) // 是否是组合活动+-
      this.updateLoadingStatus(item, true)
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap.present == true
              if (gItem?.checked) {
                if (!flag) {
                  selectedProductList.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType
                  })
                }
              }
              // 31自动换购 32手动换购
              if (flag) {
                if (!isCombination && gItem.productId == item.productId && aItem.activityId == item.activityId && gItem.expandMap?.present == item.expandMap?.present) {
                  presentProductList.push({
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    selected: gItem.checked,
                    num: item.num,
                    actualPrice: gItem.actualPrice,
                    price: gItem.price
                  })
                } else {
                  presentProductList.push({
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    selected: gItem.checked,
                    num: gItem.num,
                    actualPrice: gItem.actualPrice,
                    price: gItem.price
                  })
                }
              }
            }
          }
        }
      }
      let productList = []
      if (isCombination) {
        productList = item?.productList.map(i => { return { id: i.id, num: i.num } })
      } else {
        productList.push({
          id: item.id, num: item.num
        })
      }
      this.isLoading = true
      updateSkuNum({
        addressCode: this.customerObj.receiveDistrictNo,
        customerTypeId: this.customerObj?.customerTypeId,
        productList,
        presentProductList,
        selectedProductList
      }).then(res => {
        this.isLoading = false
        if (res.data.status == '1') {
          if (skuNumCount >= 1) {
            this.updateCheckedData(res.data)
            return
          }
          // 设置购物车选中商品缓存数据
          this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
          Modal.info({
            content: '该商品参与的活动已变化',
            okText: '我知道了',
            onOk: () => {
              skuNumCount++
              this.getCartListFn()
            }
          })
        } else {
          // 活动商品库存校验
          const { productStockCheckList } = res.data;
          if (productStockCheckList && productStockCheckList.length > 0) {
            this.onProductStockChange(res.data)
          }
          this.updateCheckedData(res.data)
        }
        this.updateLoadingStatus(item, false)
      }).catch(err => {
        this.isLoading = false
        this.updateLoadingStatus(item, false)
        // 多页签，操作购物车时不同情况的返回状态
        if (err?.data?.code === 566 || err?.data?.code === 569) {
          Modal.info({
            content: `${err?.data?.message || '商品信息有变化'}`,
            okText: '我知道了',
            title: '温馨提示',
            onOk: () => {
              this.queryCart()
            }
          })
        } else {
          Message.error(err?.data?.message)
        }
      })
    },
    // 全选
    updateCheckedAll(data) {
      let cancelProductList = []
      this.checkedAllStatus = !!data
      if (this.validCartList.length > 0) {
        for (const sItem of this.validCartList) {
          this.$set(sItem, 'checked', data)
          for (const aItem of sItem?.activityList) {
            this.$set(aItem, 'checked', data)
            if (!data) {
              aItem.productList.map(item => { return { ...item, activityId: aItem.activityId, activityType: aItem.activityType } })
              const cancelProducts = aItem.productList.filter(item => item.expandMap.present != true) || []
              cancelProductList = [...cancelProductList, ...cancelProducts]
            }
            for (const gItem of aItem?.productList) {
              if (!(gItem.isLimit == 1 && gItem.residualQty <= 0)) {
                this.$set(gItem, 'checked', data)
              }
            }
          }
        }
      }
      if (this.isEdit) {
        if (this.invalidCartList.length > 0) {
          for (const IItem of this.invalidCartList) {
            this.$set(IItem, 'checked', data)
          }
        }
      }
      if (!this.isEdit && this.validCartList.length > 0) {
        this.updateSkuChecked(cancelProductList)
      }
    },
    //  判断是否选中
    getCheckFlag(productInfo) {
      // 2润划算 5近效期 41固定组合 42自定义组合
      const activityTypeList = ['2', '5', '41', '42']
      // 31满换自动加购 32满换手动加购
      const specialActivityTypeList = ['31', '32']
      const activityType = productInfo?.activityType
      const cachedProductList = JSON.parse(localStorage.getItem('cachedProductList') || '[]')
      // 如果是活动商品，就根据商品ID和活动ID判断是否选中
      if (activityTypeList.includes(activityType)) {
        return cachedProductList.some(item => item.productId == productInfo.productId && item.activityId == productInfo.activityId)
      } else if (specialActivityTypeList.includes(activityType)) {
        return cachedProductList.some(item => item.productId == productInfo.productId)
      } else {
        return cachedProductList.some(item => item.productId == productInfo.productId && !activityTypeList.includes(item.activityType))
      }
    },

    initCheckedData(data) {
      const cancelProductList = []
      if (this.validCartList.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              if (gItem.selected || this.getCheckFlag(gItem)) {
                this.$set(gItem, 'checked', true)
              } else {
                this.$set(gItem, 'checked', false)
              }
            }
            if (aItem?.productList?.some((i) => !i.checked)) {
              this.$set(aItem, 'checked', false)
            } else {
              this.$set(aItem, 'checked', true)
            }
          }
          if (sItem?.activityList?.some((i) => !i.checked)) {
            this.$set(sItem, 'checked', false)
          } else {
            this.$set(sItem, 'checked', true)
          }
        }
      }
      if (this.validCartList.some((i) => !i.checked)) {
        this.checkedAllStatus = false
      } else {
        this.checkedAllStatus = true
      }
      if (this.validCartList.length > 0) {
        this.updateSkuChecked(cancelProductList)
        localStorage.setItem('cachedProductList', JSON.stringify([]))
      }
    },
    // 刷新页面数据
    refreshPage() {
      this.getCartListFn()
    },
    // 查询购物车，在购物车页面
    queryCart() {
      this.getCartListFn()
      this.$store.dispatch('car/updateCartNum')
    },
    // 判断是否显示电子资料交换弹窗
    async checkShowExchangePopup() {
      const { code, data } = await electronExchangeagreeFalg()
      if (code === 200 && data && data[0]?.lastMsgTitle) {
        this.showExchangePopup = true
        this.exchangePopupObj = data[0]
      }
    },
    // 事件跟踪
    eventTrack() {
      // 记录页面渲染结束时间
      this.endTime = new Date().getTime()
      // 计算渲染时长
      this.renderTime = this.endTime - this.startTime
      this.startTime = null
      // 可以在这里进行其他操作，比如发送渲染时长到服务器
      this.$nextTick(() => {
        zhugeBrowsePageTrack(this.$zhuge, {
          '页面标题': '购物车页面',
          '页面请求数量': 7,
          '页面加载时长': parseFloat(this.renderTime),
          'page_url': '/car'
        })
      })
    },
    // 监听商品库存变化
    onProductStockChange(data) {
      const { productStockCheckList } = data
      console.log('xxx', productStockCheckList)
      const tempArr = productStockCheckList.filter((item) => ![ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(item.activityType))
      let productList = []
      if (tempArr && tempArr.length > 0) {
        tempArr.forEach((item) => {
          productList = [...productList, ...item.productList]
        })
      }
      this.productStockCheckList = productList
      this.combinationStockCheckList = productStockCheckList.filter((item) => [ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(item.activityType))  
      this.batchVerifyList = null
      this.changeGoodsList = null
      this.checkGoodType = "queryCartGoodChange"
      this.goodsChangeModal = true
    } 
  }
}
</script>

<style scoped lang="less">
.car {
  background-color: #f5f5f5;

  .car-content {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    .car-box {
      width: 1200px;
    }
  }
}

.total-address {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .total {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: flex-end;
    margin-right: 9px;

    .total-big {
      font-size: 16px;
      line-height: 24px;
    }

    .total-num {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .address {
    display: flex;
    align-items: center;

    img {
      width: 13px;
      height: 15px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

::v-deep .auth-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;

    .ivu-modal-content {
      .ivu-modal-body {
        .tip-content {
          text-align: center;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }

          .content {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      .ivu-modal-footer {
        font-size: 16px;
        cursor: pointer;
        text-align: center;

        .left-btn {
          border: none;
        }

        .right-btn {
          border: none;
          background: #f99d33;
          color: #FFFFFF;
        }

        .single {
          .right-btn {
            width: 260px;
          }
        }
      }
    }
  }
}
</style>
